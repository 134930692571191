import { FC, ReactNode, useEffect } from 'react'
import cn from 'classnames'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { IModalFooter, ModalFooter } from 'features/Modal_V2/ModalFooter'
import styles from './styles.module.sass'

interface IModal extends Partial<IModalFooter> {
  title?: string | undefined | ReactNode
  isOpen: boolean
  applyContainerMobileStyles?: boolean
  onClose: () => void
  children?: ReactNode
  childrenStyles?: string
  headerStyles?: string
  headerAndTitleStyles?: string
  width?: number | string
  height?: number | string
  scrollableContent?: ReactNode
  footerWithScrollableContent?: ReactNode
  isFullScreenOnMobile?: boolean
  isLoading?: boolean
  isNonClosable?: boolean
  containerStyles?: string
}

export const Modal: FC<IModal> = ({
  title,
  isOpen,
  onClose,
  children,
  childrenStyles,
  headerStyles,
  headerAndTitleStyles,
  width = 560,
  height,
  applyContainerMobileStyles = true,
  primaryButtonText,
  primaryButtonOnClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  scrollableContent,
  footerWithScrollableContent,
  footerStyles,
  buttonStyles,
  isFullScreenOnMobile = false,
  secondaryButtonText,
  secondaryButtonOnClick,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading,
  primaryButtonType,
  secondaryButtonType,
  isLoading = false,
  isNonClosable = false,
  containerStyles
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.body.style.overflow = 'auto'
      if (isOpen) document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  const handleStopPropagation = (e: any) => {
    e.stopPropagation()
  }

  if (!isOpen) return null
  return (
    <div className={cn(styles.wrapper, isFullScreenOnMobile && styles.mobileWrapper)} onClick={handleStopPropagation}>
      <div
        className={cn(
          styles.container,
          (isFullScreenOnMobile && applyContainerMobileStyles) && styles.mobileContainer,
          containerStyles
        )}
        style={{ width, height }}
      >
        <div className={cn(styles.headerAndTitle, headerAndTitleStyles)}>
          {(title || !isNonClosable) && (
            <div className={cn(styles.header, headerStyles)}>
              {title && (
                <div className={styles.title}>
                  {title}
                  {isLoading && <div className={styles.loading}><PreloaderIcon stroke="#D7DFED" /></div>}
                </div>
              )}
              {!isNonClosable && (
                <div className={styles.close} onClick={onClose}><CloseIcon /></div>
              )}
            </div>
          )}
          {children && (
            <div className={cn(styles.children, childrenStyles)}>
              {children}
            </div>
          )}
        </div>
        {!isLoading && scrollableContent && (
          <div className={styles.scrollableContent}>
            {scrollableContent}
          </div>
        )}
        {!isLoading && (footerWithScrollableContent || (
          <ModalFooter
            primaryButtonType={primaryButtonType}
            secondaryButtonType={secondaryButtonType}
            footerStyles={footerStyles}
            primaryButtonText={primaryButtonText}
            buttonStyles={buttonStyles}
            primaryButtonOnClick={primaryButtonOnClick}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            isPrimaryButtonLoading={isPrimaryButtonLoading}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonOnClick={secondaryButtonOnClick || onClose}
            isSecondaryButtonDisabled={isSecondaryButtonDisabled}
            isSecondaryButtonLoading={isSecondaryButtonLoading}
          />
        ))}
      </div>
    </div>
  )
}
