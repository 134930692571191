import React, {
  forwardRef,
  ReactNode, useEffect, useRef, useState
} from 'react'
import {
  UseFormRegister, UseFormSetValue, UseFormTrigger
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { LENGTH_LIMITS, REGEX } from 'common/constants'
import { useInputFocusDetect } from 'common/hooks/useInputFocusDetect'
import { useInputLabel } from 'common/hooks/useInputLabel'
import { Typography, TypographyVariants } from 'common/typography'
import { isDigitsOnly } from 'common/utils/numbers'
import { truncateValue } from 'common/utils/truncateValue'
import { FooterInput } from 'features/FormInput_V2/FooterInput'
import { selectErrorMsgsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IFormInput extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'title'> {
  maxCharCount?: number
  minCharCount?: number
  minCharCountErrorText?: string
  showMaxCharCount?: boolean
  height?: number
  className?: string
  errorMsg?: string
  register?: UseFormRegister<any>
  error?: string
  isRequired?: boolean
  fieldName: string
  fieldErrorNames?: string[]
  title?: ReactNode;
  clearErrors?: any,
  isTextarea?: boolean,
  isNumber?: boolean,
  setValue?: UseFormSetValue<any>,
  helperText?: string,
  defaultValue?: string
  isBorderRed?: boolean,
  width?: number,
  trigger?: UseFormTrigger<any>;
  placeholder?: string
  regex?: any;
  regexValidationMessage?: string;
  isSubmitted?: boolean;
  isOnEditing?: boolean;
  isEditable?: boolean;
  icon?: ReactNode;
}

export const FormInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, IFormInput>(({
  maxCharCount,
  minCharCount,
  minCharCountErrorText,
  showMaxCharCount,
  height,
  className,
  errorMsg,
  error,
  register,
  isRequired,
  fieldName,
  fieldErrorNames,
  title = '',
  clearErrors,
  isNumber = false,
  isTextarea = false,
  setValue,
  helperText = '',
  defaultValue = '',
  isBorderRed,
  width,
  onFocus,
  trigger,
  regex,
  regexValidationMessage,
  isSubmitted,
  isOnEditing,
  placeholder,
  icon,
  onChange: onChangeProp,
  ...inputProps
}, ref) => {
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const isError = !!error
  const previousValue = useRef<string>()
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const { isInputFocused } = useInputFocusDetect(inputRef)
  const [inputValue, setInputValue] = useState(defaultValue || '')
  const showLabel = useInputLabel(inputValue, inputRef)

  useEffect(() => {
    setInputValue(defaultValue || '')
  }, [defaultValue])

  useEffect(() => {
    if (isTextarea && inputRef.current) {
      adjustTextareaHeight(inputRef.current as HTMLTextAreaElement)
    }
  }, [isTextarea])

  const convertToNumber = (value: string) => {
    if (isDigitsOnly(value) || !value) {
      setValue?.(fieldName, value)
      previousValue.current = value
    } else {
      setValue?.(fieldName, previousValue.current)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChangeProp) {
      onChangeProp?.(e as React.ChangeEvent<HTMLInputElement>)
      return
    }
    const inputValue = isTextarea ? e.target.value : truncateValue(e.target.value, maxCharCount)

    if (isNumber) convertToNumber(inputValue)
    if (maxCharCount || minCharCount) setInputValue(inputValue)
    if (isTextarea && e.target instanceof HTMLTextAreaElement) {
      adjustTextareaHeight(e.target)
    }
  }

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    const element = textarea
    element.style.height = 'auto'
    element.style.height = `${element.scrollHeight + 2}px`
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (fieldErrorNames) clearErrors(fieldErrorNames)
      clearErrors(fieldName)
      onFocus?.(e)
    } else {
      onFocus?.(e)
      clearErrors(fieldName)
      if (fieldErrorNames) clearErrors(fieldErrorNames)
    }
  }

  const onBlur = () => {
    if (isSubmitted || isOnEditing) {
      trigger?.(fieldName)
    }
  }

  const segmentFromRegisterWithoutPattern = {
    valueAsNumber: isNumber,
    ...(regex && {
      pattern: { value: regex, message: regexValidationMessage }
    }),
    onChange,
    required: errorMsg || false,
    validate: (value: any) => {
      if (typeof value === 'string' && !!value?.trim()) {
        if (minCharCount && value.length < minCharCount) {
          const errorMessage = minCharCountErrorText || errorMsgsTranslations.helperTextLimitMin
          return errorMessage?.replace('%', `${minCharCount}`)
        }
        return true
      }
      return errorMsg || true
    },
    ...(maxCharCount && {
      maxLength: {
        value: maxCharCount,
        message: errorMsgsTranslations.helperTextLimit.replace('%', `${maxCharCount}`)
      }
    })
  }

  const segmentFromRegister = { pattern: REGEX.DIGITS, ...segmentFromRegisterWithoutPattern }

  const InputTitle = () => (
    <Typography
      variant={TypographyVariants.Body_2_Medium}
      tag="label"
      className={cn((!isInputFocused && (isError || isBorderRed)) && styles.invalidTitle)}
    >
      {title}{isRequired ? <span className={styles.requiredStar}>*</span> : ''}
    </Typography>
  )

  const registeredField = register?.(fieldName, isNumber ? segmentFromRegister : segmentFromRegisterWithoutPattern)

  return (
    <div
      className={styles.inputTextContainer}
      style={{ ...(isTextarea ? { width: 'auto' } : { maxWidth: width }) }}
    >
      {!!title && showLabel && <InputTitle />}
      {isTextarea ? (
        <div
          className={cn(
            styles.textareaContainer,
            className,
            isError && styles.errorBorder
          )}
        >
          <textarea
            style={{ height }}
            className={styles.textarea}
            // @ts-ignore
            onChange={onChange}
            {...register?.(fieldName, segmentFromRegisterWithoutPattern)}
            // @ts-ignore
            onBlur={onBlur}
            {...inputProps}
            // @ts-ignore
            onFocus={handleFocus}
            ref={(el) => {
              register?.(fieldName).ref(el)
              // @ts-ignore
              inputRef.current = el
              if (typeof ref === 'function') {
                ref(el)
              } else if (ref) {
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                ref.current = el
              }
            }}
            placeholder={showLabel ? '' : placeholder}
          />
        </div>
      ) : (
        <input
          autoComplete="off"
          style={{ height }}
          className={cn(
            className,
            (isError || isBorderRed) && styles.errorBorder,
            !!icon && styles.withIcon
          )}
          onChange={onChange}
          {...(registeredField ? {} : { value: inputValue })}
          {...registeredField}
          onBlur={onBlur}
          {...inputProps}
          onFocus={handleFocus}
          maxLength={maxCharCount || isNumber ? maxCharCount || LENGTH_LIMITS.MAX.DIGITS_NUMBER : undefined}
          ref={(el) => {
            registeredField?.ref(el)
            // @ts-ignore
            inputRef.current = el
            if (typeof ref === 'function') {
              ref(el)
            } else if (ref) {
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              ref.current = el
            }
          }}
          placeholder={showLabel ? '' : placeholder}
        />
      )}
      {icon && <div className={styles.formInputIcon}>{icon}</div>}
      <FooterInput
        value={inputValue}
        maxCharCount={maxCharCount}
        showMaxCharCount={
          showMaxCharCount
          && (
            inputValue.length >= LENGTH_LIMITS.LIMIT_INDICATOR
            || (!!maxCharCount && inputValue.length >= maxCharCount)
          )
        }
        error={error}
        helperText={helperText}
      />
    </div>
  )
})
