import {
  REGEX,
  ROUTE_PARAMS,
  ROUTES
} from 'common/constants'

export const formatUrl = (url: string) => {
  if (url.match(REGEX.STARTS_WITH_HTTP)) return url
  return `http://${url}`
}

export const isValidUrl = (url: string) => {
  return REGEX.WEBSITE_LINK.test(url)
}

export const getQueryObject = (search: string): { [key: string]: string } => {
  const query = search.replace('?', '&').split('&')
  return query.reduce((acc, query) => {
    const [key, value] = query.split('=')
    return { ...acc, [key]: value }
  }, {})
}

export const getQueryParamsFromUrl = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, query] = url.split('?')
  if (!query) return {}
  return query.split('&').reduce((queryParams, query) => {
    const [key, value] = query.split('=')
    return ({ ...queryParams, [key]: value })
  }, {} as { [key: string]: string })
}

export const getUserDetailsPath = (
  userParams: { username?: string; uid?: string; myUid?: string }
): string => {
  return userParams.username
    ? getUserDetailsPathByUsername(userParams.username)
    : getUserDetailsPathByUid(userParams.uid || '', userParams.myUid || '')
}

export const getUserDetailsPathByUid = (uid: string, myUid: string): string => {
  if (uid === myUid) return ROUTES.PROFILE.replace(ROUTE_PARAMS.UID, uid)
  return ROUTES.USER.replace(ROUTE_PARAMS.UID, uid)
}

export const getUserDetailsPathByUsername = (username: string): string => {
  return ROUTES.USERNAME.replace(ROUTE_PARAMS.USERNAME, username)
}

export const getUserVacancyDetails = (
  uid: string
): string => {
  return ROUTES.LISTINGS.replace(ROUTE_PARAMS.UID, uid)
}

export const getUserSpecialtyDetails = (
  uid: string
): string => {
  return ROUTES.LISTINGS.replace(ROUTE_PARAMS.UID, uid)
}

export const isExistingRoute = (pathname: string): boolean => {
  return pathname.startsWith(ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, ''))
}
