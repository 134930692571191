import { FC, useState } from 'react'
import { DiscardModal } from 'features/DiscardModal'

export const withDiscardModal = (Component: FC<any>) => (props: any) => {
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)
  const {
    onClose,
    modalTitle,
    modalSubtitle,
    secondaryButtonText,
    primaryButtonText
  } = props

  return (
    <>
      <Component {...props} onOpenDiscardModal={() => setIsDiscardModalOpen(true)} />
      <DiscardModal
        isOpen={isDiscardModalOpen}
        modalTitle={modalTitle}
        modalSubtitle={modalSubtitle}
        secondaryButtonText={secondaryButtonText}
        primaryButtonText={primaryButtonText}
        onDiscard={() => {
          setIsDiscardModalOpen(false)
          onClose()
        }}
        onClose={() => setIsDiscardModalOpen(false)}
      />
    </>
  )
}
