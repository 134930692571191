import { FC, ReactChild, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { Contact } from 'common/components/Contact'
import { GroupItem } from 'common/components/GroupItem'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { SpinnerIcon } from 'common/icons_V2/SpinnerIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { formatUserName } from 'common/utils/formatUserName'
import { GroupLocationState, GroupType } from 'features/Contacts/Network/GroupPage/types'
import { NetworkFilters } from 'features/Contacts/Network/MyNetwork'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { UserType } from 'features/MyProfile/types'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface ContactListProps {
  title: ReactChild,
  emptyStateTitle?: ReactChild,
  items: UserType[] | GroupType[]
  searchString: string
  trustedUids: string[]
  allContactsUids: string[],
  getContactInfo?: (contact: UserType) => string,
  getGroupInfo?: (group: GroupType) => string,
  getGroupButton?: (group: GroupType) => string,
  buttonType?: string,
  showLoadingInAllPage?: boolean,
  isShowButtonVisible?: boolean,
  showTitle?: boolean,
  renderEmptySearchState: () => ReactNode,
  onShowGlobalSearchData: () => void,
  isGroup?: boolean
}

export const ContactList: FC<ContactListProps> = ({
  title,
  emptyStateTitle,
  items,
  searchString,
  trustedUids,
  allContactsUids,
  getContactInfo,
  getGroupInfo,
  getGroupButton,
  buttonType,
  showLoadingInAllPage,
  isShowButtonVisible,
  renderEmptySearchState,
  onShowGlobalSearchData,
  showTitle,
  isGroup
}) => {
  const history = useHistory()
  const { navigateToProfile } = useProfileNavigation()
  const networkFilters = useSelector(selectNetworkFilters)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const isEmptyInFilteredPage = items.length === 0 &&
    (networkFilters?.[0] !== NetworkFilters.ALL && !!networkFilters?.length)
  const isContact = items.length > 0 && getContactInfo

  const handleGroupClick = (groupRef: string) => {
    const filterState: GroupLocationState = { filter: networkFilters[0] }
    history.push({
      state: filterState,
      pathname: ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, groupRef)
    })
  }

  const renderItems = (title: string | ReactChild) => {
    if (isContact) {
      return items.map((contact: any) => {
        const {
          photoURL, uid, photo, username
        } = contact
        return (
          <Contact
            key={`${uid}${title}`}
            isTrusted={trustedUids.includes(uid)}
            isContact={allContactsUids.includes(uid)}
            searchString={searchString}
            onClick={() => navigateToProfile(uid, username)}
            name={formatUserName(contact)}
            photoUrl={photoURL}
            photo={photo}
            trustedByData={getContactInfo(contact)}
            uid={uid}
            buttonType={buttonType}
          />
        )
      })
    } if (isGroup) {
      return items.map((group: any) => {
        const {
          id, url, name, photoURL, uid, photo
        } = group
        return (
          <GroupItem
            key={`${url}${title}`}
            name={name}
            id={id}
            photoUrl={photoURL}
            photo={photo}
            groupRef={url}
            uid={uid}
            searchString={searchString}
            buttonType={getGroupButton?.(group)}
            groupData={getGroupInfo?.(group)}
            onClick={() => handleGroupClick(url)}
          />
        )
      })
    }
    switch (networkFilters[0]) {
      case NetworkFilters.FIRST_LEVEL:
      case NetworkFilters.SECOND_LEVEL:
      case NetworkFilters.GROUP:
      case NetworkFilters.ALL_CONTACTS:
      case NetworkFilters.GLOBAL_SEARCH:
        return renderEmptySearchState()
      case NetworkFilters.ALL:
      default:
        return (
          <>
            {isShowButtonVisible && (
              <GhostButton
                title={myNetworkTranslations.contactsGlobalShowMore}
                onClick={() => onShowGlobalSearchData()}
              />
            )}
            {!isShowButtonVisible && emptyStateTitle && (
              <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="div" className={styles.emptyState}>
                {emptyStateTitle}
              </Typography>
            )}
          </>
        )
    }
  }

  return (
    <div className={cn(isEmptyInFilteredPage ? styles.emptyContactsContent : styles.contactsContent)}>
      {showTitle && (
        <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="span" className={styles.subtitle}>
          {title}
        </Typography>
      )}
      <div className={cn(isEmptyInFilteredPage ? styles.emptyContactsList : styles.contactsList)}>
        {showLoadingInAllPage ? <div className={styles.spinner}><SpinnerIcon spinner /></div> : renderItems(title)}
      </div>
    </div>
  )
}
