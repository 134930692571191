import {
  lazy, Suspense, useCallback, useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect, Route, Switch, useHistory
} from 'react-router-dom'
import { init } from 'common/actions'
import { Preloader } from 'common/components/Preloader'
import { SplashScreen } from 'common/components/SplashScreen'
import { ROUTES } from 'common/constants'
import { useSW } from 'common/hooks/useSW'
import { useWithScrollTop } from 'common/hooks/useWithScrollTop'
import { getAppInitialized, getIsInitialLogin, selectLanguage } from 'common/selectors'
import { checkRequestPublicProfile } from 'common/utils/checkRequestPublicProfile'
import { getAuth } from 'features/Auth/selectors'
import { Network } from 'features/Contacts/Network'
import { ErrorModal } from 'features/ErrorModal'
import { Layout } from 'features/Layout'
import { PublicLayout } from 'features/Layout/PublicLayout'
import { Modals } from 'features/Modals'
import { Notifications } from 'features/Notifications'
import { ToastManager } from 'features/ToastManager'
import { listenLokaliseChanges } from 'features/Translations/actions'
import { getVideoChat } from 'features/VideoChat/selectors'
import { AuthPublicProfile } from 'routes/PublicProfile'

const LandingPage = lazy(() => import('features/LandingPage/index').then(({ LandingPage }) => ({ default: LandingPage })))
const Auth = lazy(() => import('features/Auth').then(({ Auth }) => ({ default: Auth })))
const SignUp = lazy(() => import('features/Auth/components/SignUp').then(({ SignUp }) => ({ default: SignUp })))
const Admin = lazy(() => import('features/Admin').then(({ Admin }) => ({ default: Admin })))
const Home = lazy(() => import('features/Home/index').then(({ Home }) => ({ default: Home })))
const Listing = lazy(() => import('features/Listing').then(({ Listing }) => ({ default: Listing })))
const User = lazy(() => import('features/UserProfile/').then(({ UserProfile }) => ({ default: UserProfile })))
const Conversations = lazy(() => import('features/Conversations').then(({ Conversations }) => ({ default: Conversations })))
const Settings = lazy(() => import('features/Settings').then(({ Settings }) => ({ default: Settings })))
const MyProfile = lazy(() => import('features/MyProfile').then(({ MyProfile }) => ({ default: MyProfile })))
const ClosedVacancies = lazy(() => import('features/ClosedVacancies').then(({ ClosedVacancies }) => ({ default: ClosedVacancies })))
const GroupPage = lazy(() => import('features/Contacts/Network/GroupPage').then(({ GroupPage }) => ({ default: GroupPage })))
const Support = lazy(() => import('features/Support').then(({ Support }) => ({ default: Support })))
const PrivacyPolicy = lazy(() => import('features/PrivacyPolicy'))
const TermsAndConditions = lazy(() => import('features/TermsAndConditions'))
const PublicProfile = lazy(() => import('features/PublicProfile').then(({ PublicProfile }) => ({ default: PublicProfile })))
const NotFound = lazy(() => import('features/NotFound').then(({ NotFound }) => ({ default: NotFound })))

export const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(getAuth)
  const initialized = useSelector(getAppInitialized)
  const { room } = useSelector(getVideoChat)
  const isInitialLogin = useSelector(getIsInitialLogin)
  const language = useSelector(selectLanguage)

  useSW()

  useEffect(() => {
    const unsubscribe = init(history, dispatch)
    return () => unsubscribe()
  }, [isInitialLogin])

  useEffect(() => {
    if (language) {
      const unsubscribe = listenLokaliseChanges(dispatch, language)
      return () => unsubscribe()
    }
    return () => {}
  }, [dispatch, language])

  const onBeforeunload = useCallback((e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  useEffect(() => {
    if (room) window.addEventListener('beforeunload', onBeforeunload)
    return () => {
      if (room) window.removeEventListener('beforeunload', onBeforeunload)
    }
  }, [room])

  if (!initialized || auth === undefined) return <SplashScreen />
  const paramsPublicProfile = checkRequestPublicProfile(history.location.search)
  const isPublicPage = !auth && !!paramsPublicProfile

  return (
    <Suspense fallback={<Preloader />}>
      <Switch>
        <Redirect from={ROUTES.CONVERSATIONS} to={ROUTES.MESSAGES} />
        <Redirect from={ROUTES.CHAT} to={ROUTES.MESSAGES} />
        <Redirect from={ROUTES.SURF} to={ROUTES.EXPLORE} />
        <Route path={ROUTES.LANDING} exact component={LandingPage} />
        <Route path={ROUTES.AUTH} component={Auth} />
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        {isPublicPage && (
          <PublicLayout>
            <Switch>
              <Route path={ROUTES.NOT_FOUND} component={NotFound} />
              <Route path={ROUTES.PUBLIC_PROFILE} component={PublicProfile} />
              <Route path="*">
                <Redirect to={ROUTES.NOT_FOUND} />
              </Route>
            </Switch>
          </PublicLayout>
        )}
        {!auth && (
          <PublicLayout disableLoginOnMobile>
            <Switch>
              <Route path={ROUTES.SUPPORT} component={Support} />
              <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
              <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsAndConditions} />
              <Route path="*" component={NotFound} />
            </Switch>
          </PublicLayout>
        )}
        <Route path="/*">
          {!isPublicPage && (
            <Layout>
              <Switch>
                <Route path={ROUTES.ADMIN} component={Admin} />
                <Route path={ROUTES.EXPLORE} component={Home} exact />
                <Route path={ROUTES.LISTINGS} component={Listing} />
                <Route path={ROUTES.CONTACTS} component={useWithScrollTop(Network)} />
                <Route path={ROUTES.MESSAGES} component={Conversations} />
                <Route path={ROUTES.SETTINGS} component={Settings} />
                <Route path={ROUTES.USER} component={User} />
                <Route path={ROUTES.PROFILE} component={MyProfile} />
                <Route path={ROUTES.CLOSED_VACANCIES} component={ClosedVacancies} />
                <Route path={ROUTES.GROUP} component={GroupPage} />
                <Route path={ROUTES.SUPPORT} component={Support} />
                <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsAndConditions} />
                <Route path={ROUTES.PUBLIC_PROFILE} component={AuthPublicProfile} />
                <Route path={ROUTES.USERNAME} component={User} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Layout>
          )}
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
      <Notifications />
      <ErrorModal />
      <Modals />
      <ToastManager />
    </Suspense>
  )
}
