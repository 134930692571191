import { useMemo } from 'react'
import { UseFormReset } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { FIELDS } from 'common/enums'
import { useSpecialityDetails } from 'common/hooks/useSpecialityDetails'
import { addSpeciality, editSpeciality } from 'features/MyProfile/actions'
import { VacancySpecialityModalWithDiscard } from 'features/MyProfile/components/VacancySpeciality_V2/VacancySpecialityModal'
import { IVacancySpecialityForm, normalizeData } from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectProfileTranslations, selectToastMessages } from 'features/Translations/selectors'

const SPECIALITY_FORM_SECTION_VALUES = {
  [FIELDS.ABOUT_JOB]: {
    createTitleKey: 'createResumeDescriptionTitle',
    titleKey: 'editResumeDescriptionTitle',
    createHelperText: 'createResumeDescriptionHelper',
    helperText: 'editResumeDescriptionHelper',
    createErrorMsgKey: 'createResumeEmptyDescriptionError',
    errorMsgKey: 'specialtyAboutErrorMessage',
    minCharCountErrorTextKey: 'specialtyMinCharCountErrorText',
    createMinCharCountErrorTextKey: 'createSpecialtyMinCharCountErrorText'
  },
  [FIELDS.URL]: {
    createUrlTitleKey: 'createResumeEmptyUrlTitle',
    urlTitleKey: 'createResumeFilledUrlTitle'
  }
}

interface IManageSpecialityModal {
  isOpen: boolean;
  isListingPage?: boolean;
  specialityId?: string;
  onClose: (limitReached: boolean) => void;
}

export const ManageSpecialityModal = ({
  isOpen,
  specialityId = '',
  onClose,
  isListingPage = false
}: IManageSpecialityModal) => {
  const dispatch = useDispatch()
  const profileTranslations = useSelector(selectProfileTranslations)
  const toastMessages = useSelector(selectToastMessages)
  const myUid = useSelector(getMyUid)
  const specialityDetails = useSpecialityDetails(myUid, specialityId)
  const isOnEditing = !!specialityId

  const initialInputValues: IVacancySpecialityForm = useMemo(() => ({
    [FIELDS.JOB_NAME]: specialityDetails.name ?? '',
    [FIELDS.LOCATIONS]: specialityDetails.locations ?? [],
    [FIELDS.JOB_TYPE]: specialityDetails.type ?? '',
    [FIELDS.ABOUT_JOB]: specialityDetails.details.description ?? '',
    [FIELDS.TAGS]: specialityDetails.tags ?? [],
    [FIELDS.LOCATION_TYPE]: {
      remote: specialityDetails.details.remote,
      onsite: specialityDetails.details.onsite,
      hybrid: specialityDetails.details.hybrid
    },
    [FIELDS.CURRENCY]: specialityDetails.details.currency ?? '',
    [FIELDS.SALARY_MAX]: specialityDetails.details.salaryMax ?? null,
    [FIELDS.SALARY_MIN]: specialityDetails.details.salaryMin ?? null,
    [FIELDS.RATE]: specialityDetails.details.payPeriod ?? '',
    [FIELDS.AUDIENCE]: specialityDetails.audience ?? '',
    [FIELDS.URL]: specialityDetails.url ? [{ url: specialityDetails.url }] : []
  }), [specialityId])

  const uploadSpeciality = (data: any, resetFormData: UseFormReset<any>) => {
    if (specialityId && specialityDetails.status) {
      return dispatch(editSpeciality(
        myUid,
        specialityId,
        normalizeData(data),
        specialityDetails.status,
        toastMessages,
        () => onClose(false)
      ))
    }

    return dispatch(addSpeciality(myUid, normalizeData(data), toastMessages, isListingPage,
      {
        onFinish: (limitReached) => {
          resetFormData()
          onClose(limitReached)
        }
      }))
  }

  return (
    <VacancySpecialityModalWithDiscard
      title={isOnEditing ? profileTranslations.openTo : profileTranslations.createOpenTo}
      initialInputValues={initialInputValues}
      sectionValues={SPECIALITY_FORM_SECTION_VALUES}
      isOnEditing={isOnEditing}
      isOpen={isOpen}
      onClose={onClose}
      uploadFormData={uploadSpeciality}
    />
  )
}
