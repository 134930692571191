import React, { FC, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { LENGTH_LIMITS, REGEX } from 'common/constants'
import { useLinks } from 'common/hooks/useLinks'
import { AddIcon } from 'common/icons/AddIcon'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { formatUrl } from 'common/utils/url'
import { FormInput } from 'features/FormInput_V2'
import type { SocialLinkType } from 'features/MyProfile/types'
import { LINK_ERRORS } from 'features/Translations/constants'
import { selectLinkErrorsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

enum FIELDS {
  LINK_URL = 'url',
  LINKS = 'links'
}

interface IEditLinks {
  links: SocialLinkType[];
  title: string,
  placeholder: string,
  error?: string,
  allowMultiple?: boolean,
  isRequired?: boolean,
}

type LinksFieldType = Pick<SocialLinkType, 'url'>

export const EditLinks: FC<IEditLinks> = ({
  links: defaultLinks = [],
  title,
  placeholder,
  error,
  allowMultiple = false,
  isRequired = false
}) => {
  const { validateLinks } = useLinks(defaultLinks)
  const linksErrorsTranslations = useSelector(selectLinkErrorsTranslations)

  const {
    control,
    clearErrors,
    setValue,
    watch,
    trigger,
    formState: {
      errors
    }
  } = useFormContext<{ links: LinksFieldType[] }>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: FIELDS.LINKS
  })

  const { [FIELDS.LINKS]: links } = watch()

  useEffect(() => {
    if (!allowMultiple && fields.length === 0) {
      append({ [FIELDS.LINK_URL]: '' }, { shouldFocus: false })
    }
  }, [allowMultiple, fields.length, append])

  const getDupLinkIdx = (index: number, { url }: LinksFieldType) => {
    return links.findIndex(
      (link, idx) =>
        index !== idx &&
        areLinksEqual(url, link.url)
    )
  }

  const areLinksEqual = (a: string, b: string) => {
    const formattedA = a?.match(REGEX.STARTS_WITH_HTTP) ? a?.split(REGEX.STARTS_WITH_HTTP)[1] : a
    const formattedB = b?.match(REGEX.STARTS_WITH_HTTP) ? b?.split(REGEX.STARTS_WITH_HTTP)[1] : b
    return formattedA === formattedB
  }

  const onChangeLinkUrl = (value: string, index: number) => {
    const isLinkEmpty = value === 'http://' || value === 'https://' || !value.length
    const linkValue = isLinkEmpty ? '' : formatUrl(value)

    setValue(
      `${FIELDS.LINKS}.${index}.${FIELDS.LINK_URL}`,
      linkValue as never,
      { shouldDirty: true }
    )
  }

  const onDeleteLink = (index: number) => {
    if (!allowMultiple) {
      setValue(`${FIELDS.LINKS}.${index}.${FIELDS.LINK_URL}`, '')
    } else {
      links.forEach((link, firstIndex) => {
        const duplicatedLinkIdx = getDupLinkIdx(firstIndex, link)
        if (duplicatedLinkIdx > -1) {
          const lastDuplicatedIndex = Math.max(firstIndex, duplicatedLinkIdx)
          clearErrors([
            `${FIELDS.LINKS}.${lastDuplicatedIndex}.${FIELDS.LINK_URL}`
          ])
        }
      })
      remove(index)
    }
  }

  const onBlur = (index: number) => {
    if (defaultLinks?.length > 0) {
      validateLinks()
      const linkUrl: string = watch(`${FIELDS.LINKS}.${index}.${FIELDS.LINK_URL}`)
      if (linkUrl && linkUrl.match(REGEX.WEBSITE_LINK)) {
        clearErrors(`${FIELDS.LINKS}.${index}.${FIELDS.LINK_URL}`)
      }
    }
  }

  return (
    <div className={styles.linksContainer}>
      <div className={styles.links}>
        {fields.map((field, index) => (
          <div className={styles.link} key={field.id}>
            <div className={styles.linkContent}>
              <div className={styles.url}>
                <Controller
                  control={control}
                  name={`${FIELDS.LINKS}.${index}.${FIELDS.LINK_URL}`}
                  rules={{
                    validate: {
                      isValidLink: (value) => {
                        const duplicatedLinkIdx = getDupLinkIdx(index, { url: value })
                        if (duplicatedLinkIdx > -1) {
                          return linksErrorsTranslations[LINK_ERRORS.DUPLICATE]
                        }
                        if (value && !value.match(REGEX.WEBSITE_LINK)) {
                          return linksErrorsTranslations[LINK_ERRORS.URL_PATTERN]
                        }
                        return true
                      }
                    }
                  }}
                  render={({ field }) => (
                    <FormInput
                      title={placeholder}
                      placeholder={placeholder}
                      fieldName={field.name}
                      maxCharCount={LENGTH_LIMITS.MAX.LINK_URL}
                      error={errors[FIELDS.LINKS]?.[index]?.url?.message || error}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e)
                        onChangeLinkUrl(e.target.value, index)
                      }}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      onBlur={() => {
                        field.onBlur()
                        onBlur(index)
                      }}
                      defaultValue={links[index]?.[FIELDS.LINK_URL] || ''}
                      icon={<div onClick={() => onDeleteLink(index)}> <CloseIcon /> </div>}
                      isRequired={isRequired}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}
        {(allowMultiple && links.length !== LENGTH_LIMITS.MAX.LINKS_COUNT) && (
          <div
            className={styles.addLinkButton}
            onClick={() => append({ [FIELDS.LINK_URL]: '' }, { shouldFocus: false })}
          >
            <Typography variant={TypographyVariants.Body_1_Regular} tag="p">
              {title}
            </Typography>
            <GhostButton
              icon={<AddIcon />}
              type="button"
            />
          </div>
        )}
      </div>
    </div>
  )
}
