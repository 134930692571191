export const Instagram = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0364 0H15.9636C7.14713 0 0 7.14713 0 15.9636V16.0364C0 24.8529 7.14713 32 15.9636 32H16.0364C24.8529 32 32 24.8529 32 16.0364V15.9636C32 7.14713 24.8529 0 16.0364 0Z" fill="url(#paint0_linear_4432_8345)" />
    <path d="M20.9515 6.51025H11.0498C8.31428 6.51025 6.08887 8.73567 6.08887 11.4712V20.5298C6.08887 23.2653 8.31428 25.4907 11.0498 25.4907H20.9515C23.687 25.4907 25.9124 23.2653 25.9124 20.5298V11.4712C25.9124 8.73567 23.687 6.51025 20.9515 6.51025ZM7.83893 11.4712C7.83893 9.70095 9.27956 8.26032 11.0498 8.26032H20.9515C22.7217 8.26032 24.1624 9.70095 24.1624 11.4712V20.5298C24.1624 22.3 22.7217 23.7407 20.9515 23.7407H11.0498C9.27956 23.7407 7.83893 22.3 7.83893 20.5298V11.4712Z" fill="white" />
    <path d="M16.0007 20.6138C18.5446 20.6138 20.6153 18.5442 20.6153 15.9993C20.6153 13.4543 18.5457 11.3848 16.0007 11.3848C13.4558 11.3848 11.3862 13.4543 11.3862 15.9993C11.3862 18.5442 13.4558 20.6138 16.0007 20.6138ZM16.0007 13.1359C17.5804 13.1359 18.8652 14.4207 18.8652 16.0004C18.8652 17.58 17.5804 18.8648 16.0007 18.8648C14.4211 18.8648 13.1363 17.58 13.1363 16.0004C13.1363 14.4207 14.4211 13.1359 16.0007 13.1359Z" fill="white" />
    <path d="M21.0424 12.1304C21.7274 12.1304 22.2857 11.5732 22.2857 10.8871C22.2857 10.201 21.7285 9.6438 21.0424 9.6438C20.3563 9.6438 19.7991 10.201 19.7991 10.8871C19.7991 11.5732 20.3563 12.1304 21.0424 12.1304Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_4432_8345" x1="4.67505" y1="27.3249" x2="27.3249" y2="4.67617" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FAAD4F" />
        <stop offset="0.35" stopColor="#DD2A7B" />
        <stop offset="0.62" stopColor="#9537B0" />
        <stop offset="1" stopColor="#515BD4" />
      </linearGradient>
    </defs>
  </svg>
)
