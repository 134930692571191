export const Messanger = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0364 0H15.9636C7.14713 0 0 7.14713 0 15.9636V16.0364C0 24.8529 7.14713 32 15.9636 32H16.0364C24.8529 32 32 24.8529 32 16.0364V15.9636C32 7.14713 24.8529 0 16.0364 0Z" fill="url(#paint0_linear_4432_8396)" />
    <path d="M25.5267 15.9995V16.0544C25.501 20.6342 22.2251 24.4606 17.892 25.3373C17.5982 25.3956 17.3011 25.4415 16.9984 25.4741C16.6711 25.5077 16.337 25.5267 16.0006 25.5267C14.6777 25.5267 13.4176 25.2566 12.2718 24.7666L7.85123 25.2375C7.09672 25.3182 6.53954 24.548 6.85233 23.8574L8.04071 21.2262C7.05076 19.725 6.47339 17.9278 6.47339 15.9995C6.47339 10.7482 10.7482 6.47339 16.0006 6.47339C20.9672 6.47339 25.0581 10.2942 25.4886 15.1508C25.5144 15.4289 25.5267 15.7147 25.5267 15.9995Z" fill="white" />
    <path d="M20.5803 13.2179C21.0074 12.8681 21.5938 13.3917 21.2933 13.8547L19.2932 16.94L18.2349 18.5723C18.0096 18.9199 17.5365 19.0028 17.2057 18.7528L15.4512 17.4221L14.3693 16.6025C14.2819 16.5364 14.1597 16.5386 14.0744 16.6081L11.4197 18.7741C10.9925 19.1228 10.4073 18.5992 10.7077 18.1362L12.5811 15.2482L13.6395 13.6159C13.8648 13.2683 14.3379 13.1854 14.6686 13.4354L16.3716 14.7258L17.4524 15.5442C17.5409 15.6103 17.6631 15.6081 17.7483 15.5386L20.5803 13.2168V13.2179Z" fill="url(#paint1_linear_4432_8396)" />
    <defs>
      <linearGradient id="paint0_linear_4432_8396" x1="4.67505" y1="27.3249" x2="27.3238" y2="4.67617" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0498FA" />
        <stop offset="0.34" stopColor="#5C5EFE" />
        <stop offset="0.73" stopColor="#F44E90" />
        <stop offset="1" stopColor="#FA8679" />
      </linearGradient>
      <linearGradient id="paint1_linear_4432_8396" x1="12.0295" y1="20.0006" x2="20.004" y2="12.0261" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0498FA" />
        <stop offset="0.34" stopColor="#5C5EFE" />
        <stop offset="0.73" stopColor="#F44E90" />
        <stop offset="1" stopColor="#FA8679" />
      </linearGradient>
    </defs>
  </svg>
)
