import { useSelector } from 'react-redux'
import cn from 'classnames'
import { ResponsiveImage } from 'common/components/ResponsiveImage/index'
import { LogoIcon } from 'common/icons_V2/LogoIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { selectOnboardingTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface ISplashScreen {
  wrapperStyles?: string
}

export const SplashScreen = ({ wrapperStyles }: ISplashScreen) => {
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  return (
    <div className={cn(wrapperStyles, styles.wrapper)}>
      <ResponsiveImage icon={<LogoIcon />} />
      <Typography variant={TypographyVariants.Desktop_UI_XXXL_Medium} tag="span" className={styles.title}>
        {onboardingTranslations.splashScreenTitle}
      </Typography>
    </div>
  )
}
