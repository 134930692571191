import { ProfileType } from 'features/MyProfile/types'

export enum Privacy {
  Public = 'public',
  Private = 'private'
}

export enum GroupRequestStatus {
  Accepted = 'accepted',
  Applied = 'applied'
}

export type UrlHistory = {
  url: string
  ts: string
  uid: string
}

export type GroupRequest = ProfileType & {
  status: GroupRequestStatus
}

export type GroupType = {
  id: string
  name: string
  owner: string
  admins: string[]
  members: string[]
  members_data?: ProfileType[]
  requests?: GroupRequest[]
  request?: Partial<GroupRequest>
  blocked?: string[]
  url: string
  photoURL?: string
  photo?: string
  created: string
  privacy: Privacy
  url_history: UrlHistory[]
  isFullData: boolean
}

export interface GroupLocationState {
  filter?: number,
  chatId?: string
}
