import React, {
  useCallback, useEffect, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import cn from 'classnames'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { CopyButton } from 'common/components/CopyButton'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { AddContactIcon } from 'common/icons_V2/AddContactIcon'
import { EmptyMyNetwork } from 'common/icons_V2/EmptyMyNetwork'
import { MobilyEmptyMyNetwork } from 'common/icons_V2/MobilyEmptyMyNetwork'
import { SpinnerIcon } from 'common/icons_V2/SpinnerIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { getInviteLink } from 'common/utils/dynamicLinks'
import { EmptyInviteContainer } from 'features/Contacts/Network/MyNetwork/components/EmptyInviteContainer'
import { actions as actionsHome } from 'features/Home/actions'
import { ShareModalWithDiscard } from 'features/Home/components/CardActionsPopup_V2/components/ShareModal'
import { getGlobalSearchUsers } from 'features/MyProfile/actions'
import { getMyUid } from 'features/MyProfile/selectors'
import { SearchInput } from 'features/SearchInput_V2'
import { SearchStateType } from 'features/SearchInput_V2/types'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { ToastType } from 'features/ToastManager/types'
import { selectInviteViaLinkTranslations, selectMyNetworkTranslations, selectToastMessages } from 'features/Translations/selectors'
import styles from './styles.module.sass'

const MIN_SEARCH_LENGTH = 3

interface IWelcomeContacts {
  searchString: string,
  setSearchString: React.Dispatch<React.SetStateAction<string>>,
  autoFocus?: boolean
}

export const WelcomeContacts: React.FC<IWelcomeContacts> = ({ searchString, setSearchString, autoFocus }) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const inviteViaLinkTranslations = useSelector(selectInviteViaLinkTranslations)
  const toastMessages = useSelector(selectToastMessages)
  const isMobile = useMobileMediaQuery()
  const myUid = useSelector(getMyUid)
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const [loadingGlobalSearchUsers, setLoadingGlobalSearchUsers] = useState(false)
  const inviteLink = getInviteLink(myUid)
  const activeRequestsRef = useRef(0)

  const isSearchStringValid = (value: string): boolean => {
    return value?.trim()?.length >= MIN_SEARCH_LENGTH
  }
  const debouncedSearch = useCallback(debounce(async (query: string) => {
    setLoadingGlobalSearchUsers(true)
    activeRequestsRef.current += 1
    try {
      const users = await getGlobalSearchUsers(query)
      if (isSearchStringValid(query)) {
        dispatch(actionsHome.setGlobalSearchUsers(users))
      }
    } catch (error) {
      dispatch(actionsToast.addToast({
        type: ToastType.ERROR,
        message: toastMessages.errorGlobalSearchMessage
      }))
    } finally {
      activeRequestsRef.current -= 1
      if (activeRequestsRef.current === 0) {
        setLoadingGlobalSearchUsers(false)
      }
    }
  }, 500), [dispatch])

  const handleSearchChange = (value: string) => {
    setSearchString(value)
    if (isSearchStringValid(value)) {
      debouncedSearch(value)
    } else {
      dispatch(actionsHome.setGlobalSearchUsers([]))
    }
  }

  useEffect(() => {
    return () => debouncedSearch.cancel()
  }, [debouncedSearch])

  return (
    <div className={styles.welcomeContactsContainer}>
      <div className={cn(styles.emptySearchContainer)}>
        <SearchInput
          onChange={handleSearchChange}
          value={searchString}
          placeholder={myNetworkTranslations.search}
          state={SearchStateType.WHITE}
          autoFocus={autoFocus}
        />
        <div>
          <PrimaryButton
            className={styles.copyButtonContainer}
            icon={<AddContactIcon />}
            title={<Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p"> {myNetworkTranslations.networkInviteButtonTitle} </Typography>}
            onClick={() => setIsOpenShareModal(true)}
          />
        </div>
      </div>
      {loadingGlobalSearchUsers
        ? <div className={styles.emptyNetworkSpinner}><SpinnerIcon spinner /></div>
        : (
          <EmptyInviteContainer
            title={myNetworkTranslations.contactsEmptyTitle}
            description={myNetworkTranslations.contactsEmptyDescription}
            icon={isMobile ? <MobilyEmptyMyNetwork /> : <EmptyMyNetwork />}
            imgAlt={myNetworkTranslations.contactsEmptyTitle}
            copyButton={(
              <CopyButton
                shareLink={inviteLink}
                title={inviteViaLinkTranslations.copyLinkShort}
                copiedTitle={inviteViaLinkTranslations.copyToClipBoardShort}
                showLink
              />
          )}
          />
        )}
      <ShareModalWithDiscard
        uid={myUid}
        isOpen={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        modalTitle={myNetworkTranslations.networkShareModalTitle}
      />
    </div>
  )
}
