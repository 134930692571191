import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { ResponsiveImage } from 'common/components/ResponsiveImage'
import { AddContactIcon } from 'common/icons_V2/AddContactIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { ShareModalWithDiscard } from 'features/Home/components/CardActionsPopup_V2/components/ShareModal'
import { getMyUid } from 'features/MyProfile/selectors'
import styles from './styles.module.sass'

interface IEmptyInviteContainer {
  title: string;
  description: string;
  photoURL?: string,
  imgAlt: string;
  inviteButtonTitle?: string;
  shareModalTitle?: string;
  copyButton: ReactNode;
  isGroupInvite?: boolean;
  inviteLink?: string;
  icon?: ReactNode;
  groupRef?: string;
  showShareModal?: boolean
}

export const EmptyInviteContainer: React.FC<IEmptyInviteContainer> = ({
  title,
  description,
  photoURL,
  imgAlt,
  icon,
  inviteButtonTitle,
  shareModalTitle,
  copyButton,
  isGroupInvite,
  inviteLink,
  groupRef,
  showShareModal
}) => {
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const myUid = useSelector(getMyUid)

  return (
    <div className={styles.emptyContainer}>
      <ResponsiveImage
        src={photoURL || ''}
        alt={imgAlt}
        size={156}
        icon={icon}
      />
      <div className={styles.emptyContainerInfo}>
        <div className={styles.emptyContainerText}>
          <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p" className={styles.title}>
            {title}
          </Typography>
          <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.description}>
            {description}
          </Typography>
        </div>
        {showShareModal && (
          <div>
            <PrimaryButton
              className={styles.copyButtonContainer}
              icon={<AddContactIcon />}
              title={<Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p"> {inviteButtonTitle} </Typography>}
              onClick={() => setIsOpenShareModal(true)}
            />
          </div>
        )}
        {copyButton}
      </div>
      <ShareModalWithDiscard
        uid={myUid}
        isOpen={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        modalTitle={shareModalTitle}
        isGroupInvite={isGroupInvite}
        inviteLink={inviteLink}
        groupRef={groupRef}
      />
    </div>
  )
}
