import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import type { AppStateType, InferActionsTypes } from 'common/types'
import { actions as actionsConversations } from 'features/Conversations/actions'
import type { ProfileType, VacancyType } from 'features/MyProfile/types'
import { actions } from 'features/Notifications/actions'

export type ActionTypes = InferActionsTypes<typeof actions | typeof actionsConversations>
export type ThunkType = ThunkAction<Promise<void | boolean>, AppStateType, Auth, ActionTypes>

export enum ErrorModalTypes {
    DEFAULT = 'default',
    CUSTOM = 'custom'
}

export interface IErrorModal {
    type: ErrorModalTypes;
    title?: string;
    description?: string;
    status?: number;
    ctaType?: string;
}

export enum NotificationTypes {
    CALL_INSTANT = 'call_instant',
    CALL_INSTANT_GROUP = 'call_instant_group',
    /**
     * @deprecated
    */
    CALL_DECLINED = 'call_declined',
    /**
     * @deprecated
    */
    CALL_FINISHED = 'call_finished',
    INVEST = 'invest',
    INTRO = 'intro',
    MY_INTRO = 'my_intro',
    INTRO_YOU = 'intro_you',
    NEW_CHAT = 'new_chat',
    SHARE = 'share',
    CALL_SCHEDULED = 'call_scheduled',
    CALL_CANCELED = 'call_canceled',
    CALL_STARTED = 'call_started',
    TWILIO_ENTER_GROUP = 'twilio_enter_group',
    /**
     * @deprecated
    */
    CALL_INVITE = 'call_invite',
    /**
     * @deprecated
    */
    CALL_ACCEPTED = 'call_accepted',
    FAVOR_VACANCY = 'favor_vacancy',
    OFFER_VACANCY = 'offer_vacancy',
    VACANCY = 'vacancy',
    RESUME = 'resume',
    NEW_VACANCY = 'new_vacancy',
    NEW_RESUME = 'new_resume',
    MODIFIED_VACANCY = 'modified_vacancy',
    MODIFIED_RESUME = 'modified_resume',
    CLOSED_VACANCY = 'closed_vacancy',
    CLOSED_RESUME = 'closed_resume',
    DELETE_RESUME = 'delete_resume',
    DELETE_VACANCY = 'delete_vacancy',
    CONTACT_DELETED = 'contact_deleted',
    GROUP_JOIN_REQUEST = 'group_join_request',
    GROUP_JOINED = 'group_joined'
}

export type NotificationJobOfferTypes = Extract<
    typeof NotificationTypes,
    | NotificationTypes.VACANCY
    | NotificationTypes.RESUME
    | NotificationTypes.NEW_VACANCY
    | NotificationTypes.NEW_RESUME
    | NotificationTypes.MODIFIED_VACANCY
    | NotificationTypes.MODIFIED_RESUME
    | NotificationTypes.CLOSED_VACANCY
    | NotificationTypes.CLOSED_RESUME
    | NotificationTypes.DELETE_RESUME
    | NotificationTypes.DELETE_VACANCY
>;

export enum NotificationStatusTypes {
    ACTIVE = 'active',
    READ = 'read',
    ARCHIVED = 'archived',
    HIDDEN = 'hidden'
}

export type IncomingCallType = {
    uid: string
    name: string
    photoUrl: string
    room: string
    token: string
    photo: string
}

export type NotificationsHistoryType = {
    [key: string]: ValueNotificationsHistoryType | NotificationHistoryJobOfferType
}

export type ValueNotificationsHistoryType = {
    id: string
    contact: string
    data: { [key: string]: any }
    status: NotificationStatusTypes | string
    ts: string
    type: NotificationTypes
    uid: string
    count?: number
    match?: VacancyType[]
}

export type NotificationHistoryJobOfferType = {
    id: string;
    status: NotificationStatusTypes | string;
    type: NotificationJobOfferTypes;
    jobDetails: VacancyType;
    data?: VacancyType; // TODO: Update name after backend update
    isEmployer: boolean;
    user: ProfileType;
    uid?: string;
}

export type NotificationsGroupType = {
    id: string
    contact: ProfileType
    data: {
        group_id: string
        group_name: string
        group_photoURL: string
        group_ref: string
        [key: string]: string
    }
    status: NotificationStatusTypes | string
    ts: string
    type: NotificationTypes
    uid: string
}
