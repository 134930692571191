import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { LENGTH_LIMITS } from 'common/constants'
import { parseBase64Image } from 'common/utils/parseBase64Image'
import { GroupType } from 'features/Contacts/Network/GroupPage/types'
import { FormInput } from 'features/FormInput_V2'
import { Modal } from 'features/Modal_V2'
import { addNewGroup, updateGroup } from 'features/MyProfile/actions'
import { Avatar } from 'features/MyProfile/components/Avatar'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

enum FIELDS {
  NAME = 'name',
  PHOTO_URL = 'photoURL',
  PHOTO = 'photo'
}

interface ICreateOrEditGroupModal {
  isOpen: boolean
  onClose: () => void
  selectGroup?: (groupId: string) => any
  group?: GroupType
}

export const CreateOrEditGroupModal = ({
  isOpen, onClose, selectGroup, group
}: ICreateOrEditGroupModal) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const [isProcessing, setIsProcessing] = useState(false)

  const {
    handleSubmit,
    register,
    clearErrors,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      [FIELDS.NAME]: group?.name || '',
      [FIELDS.PHOTO_URL]: group?.photoURL || '',
      [FIELDS.PHOTO]: group?.photo || ''
    }
  })

  const {
    [FIELDS.PHOTO_URL]: photoURL,
    [FIELDS.PHOTO]: photo,
    [FIELDS.NAME]: groupName
  } = watch()

  const onSubmit = async (data: { [key: string]: any }) => {
    if (isProcessing) return

    setIsProcessing(true)
    if (group) {
      const updatedGroup: GroupType = {
        ...group,
        name: data[FIELDS.NAME]
      }
      await dispatch(updateGroup(
        updatedGroup,
        data[FIELDS.PHOTO_URL],
        data[FIELDS.PHOTO],
        () => {
          setIsProcessing(false)
          onClose()
        }
      ))
    } else {
      await dispatch(addNewGroup(
        data[FIELDS.NAME],
        data[FIELDS.PHOTO_URL],
        (groupRef) => {
          selectGroup?.(groupRef)
          setIsProcessing(false)
        }
      ))
    }
  }

  const handleClose = () => {
    if (isProcessing) return
    onClose()
  }

  const onCropImage = async (imageUrl: string) => {
    const { base64Data } = parseBase64Image(imageUrl)
    setValue(FIELDS.PHOTO_URL, imageUrl)
    setValue(FIELDS.PHOTO, base64Data)
  }

  useEffect(() => {
    if (isOpen) {
      reset({
        [FIELDS.NAME]: group?.name || '',
        [FIELDS.PHOTO_URL]: group?.photoURL || '',
        [FIELDS.PHOTO]: group?.photo || ''
      })
    }
  }, [group, isOpen, reset])

  return (
    <Modal
      isOpen={isOpen}
      width="460px"
      height="420px"
      title={group
        ? myNetworkTranslations.editGroupModalTitle
        : myNetworkTranslations.createGroupModalTitle}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.createGroupForm}>
        <Avatar
          photoURL={photoURL}
          photo={photo}
          uploadPhotoTitle={myNetworkTranslations.createGroupModalSetPhoto}
          width={96}
          onCropImage={onCropImage}
          isGroupImage
        />
        <FormInput
          title={myNetworkTranslations.createGroupModalInputTitle}
          placeholder={myNetworkTranslations.createGroupModalInputTitle}
          fieldName={FIELDS.NAME}
          maxCharCount={LENGTH_LIMITS.MAX.GROUP_NAME}
          error={errors[FIELDS.NAME]?.message}
          errorMsg={myNetworkTranslations.createGroupModalErrorMessage}
          register={register}
          clearErrors={clearErrors}
          defaultValue={groupName}
          isRequired
          showMaxCharCount
        />
        <div className={styles.modalActions}>
          <SecondaryButton
            title={myNetworkTranslations.createGroupModalCancelButton}
            onClick={handleClose}
          />
          <PrimaryButton
            type="submit"
            className={styles.primaryButton}
            title={group
              ? myNetworkTranslations.createGroupModalUpdateButton
              : myNetworkTranslations.createGroupModalCreateButton}
            isLoading={isProcessing}
          />
        </div>
      </form>
    </Modal>
  )
}
