import { ThunkAction } from 'redux-thunk'
import { Auth } from 'firebase/auth'
import { AppStateType, InferActionsTypes } from 'common/types'
import { actions } from 'features/ToastManager/actions'

export type ActionTypes = InferActionsTypes<typeof actions>
export type ThunkType = ThunkAction<Promise<void | boolean> | void, AppStateType, Auth, ActionTypes>

export enum ToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info'
}

export interface Toast {
    id: string
    type: ToastType
    message: string
    isTopPriority?: boolean
}
