import envConfig from 'config'
import { GroupType } from 'features/Contacts/Network/GroupPage/types'

export const isGroupInviteLink = (link: string) => {
  const regex = new RegExp(`^${envConfig.baseUrl}/group/[a-zA-Z0-9_-]+$`)
  return regex.test(link)
}

export const getGroupRefFromLink = (link: string) =>
  (isGroupInviteLink(link) ? link.split('/').pop() : null)

export const isMyGroup = (uid: string, group?: GroupType): boolean => {
  return group?.members?.includes(uid) || false
}

export const isBlockedMember = (uid: string, group?: GroupType | null): boolean => {
  return group?.blocked?.includes(uid) || false
}
