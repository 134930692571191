import {
  FC,
  MouseEvent,
  useEffect,
  useState
} from 'react'
import cn from 'classnames'
import { AVAILABLE_IMAGE_SIZES, PROFILE_IMAGE_EXTENSION } from 'common/components/Image_V2/constants'
import { PeopleIcon } from 'common/icons_V2/PeopleIcon'
import { PersonIcon } from 'common/icons_V2/PersonIcon'
import { calculateBorderRadius } from 'common/utils/calculateBorderRadius'
import { getImageSrcFromBase64 } from 'common/utils/getImageSrcFromBase64'
import styles from './styles.module.sass'

interface IImage {
  photoURL: string
  photo?: string
  alt?: string
  width?: number
  onClick?: (e: MouseEvent<HTMLElement>) => void
  isRounded?: boolean
  isGroupImage?: boolean
}

export const Image: FC<IImage> = ({
  photoURL, photo, alt, width = 20, isRounded, isGroupImage, onClick, ...imageProps
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isImageExists, setIsImageExists] = useState(!!photo || !!photoURL)
  const imageStyles = {
    width: `${width}px`,
    height: `${width}px`,
    borderRadius: `${isRounded ? width / 2 : calculateBorderRadius(width)}px`,
    position: photo || photoURL ? 'absolute' : 'unset'
  } as const

  const findClosestLargerSize = (size: number) => {
    return AVAILABLE_IMAGE_SIZES.find((e) => e >= size) ?? AVAILABLE_IMAGE_SIZES[AVAILABLE_IMAGE_SIZES.length - 1]
  }

  const getOptimizedPhotoURL = (url: string, size: number) => {
    if (url?.includes(PROFILE_IMAGE_EXTENSION) && !isGroupImage) {
      const closestSize = findClosestLargerSize(size)
      const [base, query] = url.split('?')
      return `${base.replace(PROFILE_IMAGE_EXTENSION, `.${closestSize}${PROFILE_IMAGE_EXTENSION}`)}?${query}`
    }
    return url
  }

  const optimizedPhotoURL = getOptimizedPhotoURL(photoURL, width)

  useEffect(() => {
    setIsImageExists(!!photo || !!photoURL)
  }, [photoURL, photo])

  const renderImage = (src: string, isImage: boolean) => (
    <img
      style={imageStyles}
      src={src}
      alt={alt || ''}
      onLoad={() => {
        setIsImageLoaded(isImage)
      }}
      onError={() => {
        setIsImageLoaded(true)
        setIsImageExists(false)
      }}
      {...imageProps}
    />
  )

  return (
    <>
      {!isImageLoaded && !photo && !!photoURL && <div style={imageStyles} className={styles.skeleton} />}
      {isImageExists ? (
        <div
          onClick={onClick}
          className={styles.baseImageWrapper}
          style={{ width: `${width}px`, height: `${width}px` }}
        >
          {!isImageLoaded && renderImage(getImageSrcFromBase64(photo || '', photoURL), false)}
          {optimizedPhotoURL && renderImage(optimizedPhotoURL, true)}
        </div>
      ) : (
        <div onClick={onClick} style={imageStyles} className={cn(styles.personIcon, isRounded && styles.isRounded)}>
          {isGroupImage ? <PeopleIcon size={width} /> : <PersonIcon size={width} />}
        </div>
      )}
    </>
  )
}
