import uniq from 'lodash/uniq'
import { ACTION_TYPES } from 'features/Home/actions'
import {
  type ActionTypes, MemoizedUserType, NotificationHistoryTypes, UidByUsernameType
} from 'features/Home/types'
import type { ProfileType, UserType, VacancyType } from 'features/MyProfile/types'

type InitialState = {
  memoizedUsers: MemoizedUserType,
  globalSearchUsers: ProfileType[],
  uidsByUsername: UidByUsernameType,
  otherUsersVacancies: { [key: string]: VacancyType[] },
  otherUsersTrusted: { [key: string]: UserType[] },
  notificationHistoryIds: { intro: string[]; jobOffer: string[], archivedJobOffer: string[] },
  vacanciesIsLoading: boolean,
  deletedUsers: string[]
}

const initialState: InitialState = {
  memoizedUsers: {},
  globalSearchUsers: [],
  uidsByUsername: {},
  otherUsersVacancies: {},
  otherUsersTrusted: {},
  notificationHistoryIds: { intro: [], jobOffer: [], archivedJobOffer: [] },
  vacanciesIsLoading: false,
  deletedUsers: []
}

export const HomeReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case ACTION_TYPES.SET_NOTIFICATION_HISTORY_IDS: {
      if (action.payload.type === NotificationHistoryTypes.INTRO) {
        return {
          ...state,
          notificationHistoryIds: {
            ...state.notificationHistoryIds,
            intro: action.payload.ids
          }
        }
      }

      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          jobOffer: action.payload.ids
        }
      }
    }
    case ACTION_TYPES.PREPEND_NOTIFICATION_HISTORY_ID: {
      if (action.payload.type === NotificationHistoryTypes.INTRO) {
        return {
          ...state,
          notificationHistoryIds: {
            ...state.notificationHistoryIds,
            intro: uniq([action.payload.notificationId, ...state.notificationHistoryIds.intro])
          }
        }
      }

      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          jobOffer: uniq([action.payload.notificationId, ...state.notificationHistoryIds.jobOffer])
        }
      }
    }
    case ACTION_TYPES.DELETE_JOB_OFFER_NOTIFICATION: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          jobOffer: state.notificationHistoryIds.jobOffer.filter((id) => id !== action.payload.notificationId)
        }
      }
    }
    case ACTION_TYPES.DELETE_JOB_OFFER_NOTIFICATIONS: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          jobOffer: state.notificationHistoryIds.jobOffer.filter((id) => !action.payload.notificationIds.includes(id))
        }
      }
    }
    case ACTION_TYPES.DELETE_INTRO_NOTIFICATION: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          intro: state.notificationHistoryIds.intro.filter((id) => id !== action.payload.notificationId)
        }
      }
    }
    case ACTION_TYPES.SET_USERS_VACANCIES: {
      return {
        ...state,
        otherUsersVacancies: {
          ...state.otherUsersVacancies,
          [action.uid]: action.vacancies
        },
        vacanciesIsLoading: false
      }
    }
    case ACTION_TYPES.SET_USERS_TRUSTED: {
      return {
        ...state,
        otherUsersTrusted: {
          ...state.otherUsersTrusted,
          [action.uid]: action.trusted
        }
      }
    }
    case ACTION_TYPES.SET_USERS_VACANCIES_LOADING: {
      return {
        ...state,
        vacanciesIsLoading: action.payload
      }
    }
    case ACTION_TYPES.ADD_MEMOIZED_USER: {
      const user = {
        ...action.payload.user,
        isFullData: action.payload.isFullData
      }
      const updatedState = {
        ...state,
        memoizedUsers: {
          ...state.memoizedUsers,
          [user.uid]: user
        }
      }

      if (user.username) {
        updatedState.uidsByUsername = {
          ...state.uidsByUsername,
          [user.username]: user.uid
        }
      }

      return updatedState
    }
    case ACTION_TYPES.ADD_BULK_MEMOIZED_USERS: {
      const memoizedUsers = Object.entries(action.users || {}).reduce((acc, [uid, user]) => {
        const userToInsert = {
          ...acc[uid],
          ...user
        }
        userToInsert.job = {
          ...userToInsert.job,
          title: 'job_title' in user ? user.job_title : userToInsert.job?.title,
          company: 'job_company' in user ? user.job_company : userToInsert.job?.company
        }
        return {
          ...acc,
          [uid]: userToInsert
        }
      }, state.memoizedUsers)

      const uidsByUsername = Object.entries(action.users || {}).reduce((acc, [uid, user]) => {
        if (user.username) {
          return {
            ...acc,
            [user.username]: uid
          }
        }
        return acc
      }, state.uidsByUsername)

      return {
        ...state,
        memoizedUsers,
        uidsByUsername
      }
    }
    case ACTION_TYPES.DELETE_MEMOIZED_USER: {
      const { [action.payload]: deletedUser, ...remainingUsers } = state.memoizedUsers
      const updatedState = {
        ...state,
        memoizedUsers: remainingUsers
      }

      if (deletedUser?.username) {
        const { [deletedUser.username]: _, ...remainingIndexes } = state.uidsByUsername
        updatedState.uidsByUsername = remainingIndexes
      }

      return updatedState
    }
    case ACTION_TYPES.SET_GLOBAL_SEARCH_USERS: {
      return {
        ...state,
        globalSearchUsers: action.users
      }
    }
    case ACTION_TYPES.ADD_DELETED_USER: {
      return {
        ...state,
        deletedUsers: [...state.deletedUsers, action.uid]
      }
    }
    case ACTION_TYPES.SET_ARCHIVED_NOTIFICATION_HISTORY_IDS: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          archivedJobOffer: action.payload.ids
        }
      }
    }
    case ACTION_TYPES.PREPEND_ARCHIVED_NOTIFICATION_HISTORY_ID: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          archivedJobOffer: uniq([
            action.payload.notificationId, ...state.notificationHistoryIds.archivedJobOffer
          ])
        }
      }
    }
    case ACTION_TYPES.DELETE_ARCHIVED_JOB_OFFER_NOTIFICATION: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          archivedJobOffer: state.notificationHistoryIds.archivedJobOffer.filter(
            (id) => id !== action.payload.notificationId
          )
        }
      }
    }
    case ACTION_TYPES.DELETE_ARCHIVED_JOB_OFFER_NOTIFICATIONS: {
      return {
        ...state,
        notificationHistoryIds: {
          ...state.notificationHistoryIds,
          archivedJobOffer: state.notificationHistoryIds.archivedJobOffer.filter(
            (id) => !action.payload.notificationIds.includes(id)
          )
        }
      }
    }
    default:
      return state
  }
}
