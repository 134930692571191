import type {
  ConnectOptions,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
  Room
} from 'twilio-video'
import { CustomError } from 'common/types'
import LocalStorageService from 'common/utils/LocalStorageService'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { ErrorModalTypes, NotificationTypes, ValueNotificationsHistoryType } from 'features/Notifications/types'
import { DevicesTypes, ThunkType } from 'features/VideoChat/types'

export const importTwilioVideo = async () => import('twilio-video')

export const actions = {
  setRoom: (room: Room | null) => (
    { type: 'VIDEO_CHAT__SET_ROOM', room } as const
  ),
  setLocalDataTrack: (localDataTrack: LocalDataTrack) => (
    { type: 'VIDEO_CHAT__SET_LOCAL_DATA_TRACK', localDataTrack } as const
  ),
  setDevices: (devices: MediaDeviceInfo[]) => (
    { type: 'VIDEO_CHAT__SET_DEVICES', devices } as const
  ),
  setIsGroup: (isGroup: boolean) => (
    { type: 'VIDEO_CHAT__SET_IS_GROUP', isGroup } as const
  ),
  setSelectedDevices: (devices: { [key: string]: string }) => (
    { type: 'VIDEO_CHAT__SET_SELECTED_DEVICES', devices } as const
  ),
  setSelectedVideoDevice: (videoDeviceId: string) => (
    { type: 'VIDEO_CHAT__SET_SELECTED_VIDEO_DEVICE', videoDeviceId } as const
  ),
  setViewEndCallAll: (viewEndCallAll: boolean) => (
    { type: 'VIDEO_CHAT__SET_VIEW_END_CALL_ALL', viewEndCallAll } as const
  ),
  setIsMyProfileIsOwnerOutgoingCall: () => (
    { type: 'VIDEO_CHAT__SET_IS_MY_PROFILE_IS_OWNER_OUTGOING_CALL' } as const
  ),
  reset: () => ({ type: 'VIDEO_CHAT__RESET' } as const)
}

export const connectToVideoRoom = (
  room: string,
  token: string,
  isGroup?: boolean
): ThunkType => async (dispatch, getState) => {
  const { connect, createLocalTracks, LocalDataTrack } = await importTwilioVideo()
  const localDataTrack = new LocalDataTrack()

  if (isGroup) dispatch(actions.setIsGroup(isGroup))

  navigator.mediaDevices.enumerateDevices().then((devices) => {
    // console.log('Media devices: ', devices)
    dispatch(actions.setDevices(devices))

    const selectedDevices: { [key: string]: string } = {}

    devices.forEach((device) => {
      let selectedDevice = LocalStorageService.getItem(device.kind)

      if (!selectedDevice || !devices.some(({ deviceId }) => deviceId === selectedDevice)) {
        LocalStorageService.setItem(device.kind, device.deviceId)
        selectedDevice = device.deviceId
      }

      if (!selectedDevices[device.kind]) selectedDevices[device.kind] = selectedDevice
    })

    dispatch(actions.setSelectedDevices(selectedDevices))

    return createLocalTracks({
      audio: { deviceId: selectedDevices.audioinput },
      video: { deviceId: selectedDevices.videoinput }
    })
  }).then(async (localTracks) => {
    connect(token, {
      room,
      dominantSpeaker: true,
      tracks: [...localTracks, localDataTrack]
    } as ConnectOptions)
      .then((room) => {
        const { notifications: { history } } = getState()
        const callDeclinedNotifications = Object.values(history).filter(
          ({ type }) => type === NotificationTypes.CALL_DECLINED
        ) as ValueNotificationsHistoryType[]
        if (callDeclinedNotifications.find((notification) => notification?.data?.room === room?.sid)) {
          room.disconnect()
          // @ts-ignore
          localTracks.map((track) => track?.stop?.())
        } else {
          dispatch(actions.setRoom(room))
          dispatch(actions.setLocalDataTrack(localDataTrack))
        }
      })
      .catch((err) => {
        const { status, error: errorMsg } = err as CustomError
        dispatch(actionsNotifications.addErrorMsg({
          type: ErrorModalTypes.DEFAULT,
          description: errorMsg,
          status
        }))
      })
  })
}

export const changeDevice = (kind: string, deviceId: string): ThunkType => async (dispatch, getState) => {
  const { room, selectedDevices } = getState().videoChat

  if (selectedDevices[kind] === deviceId) return

  LocalStorageService.setItem(kind, deviceId)
  dispatch(actions.setSelectedDevices({ [kind]: deviceId }))

  if (room) {
    const { createLocalAudioTrack, createLocalVideoTrack } = await importTwilioVideo()
    let track: LocalVideoTrack | LocalAudioTrack | undefined

    switch (kind) {
      case DevicesTypes.Videoinput: {
        track = await createLocalVideoTrack({ deviceId })
        break
      }
      case DevicesTypes.Audioinput: {
        track = await createLocalAudioTrack({ deviceId })
        break
      }
      default: break
    }

    if (track) {
      const { tracks } = room.localParticipant

      tracks.forEach((t) => {
        if (t.kind === track?.kind) {
          // @ts-ignore
          t.track.stop()
        }
      })

      room.localParticipant.publishTrack(track)
    }
  }
}
