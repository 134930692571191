export const LogoIcon = () => (
  <svg width="484" height="218" viewBox="0 0 484 218" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10701_62188)">
      <path d="M223.544 83.27C201.179 83.27 183.024 64.6158 183.024 41.635C183.024 18.6543 201.179 0 223.544 0C245.909 0 264.064 18.6543 264.064 41.635C264.064 64.6158 245.909 83.27 223.544 83.27ZM223.544 22.475C213.264 22.475 204.897 31.0717 204.897 41.635C204.897 52.1983 213.264 60.795 223.544 60.795C233.825 60.795 242.191 52.1983 242.191 41.635C242.191 31.0717 233.825 22.475 223.544 22.475Z" fill="#306FD1" />
      <path d="M285.446 150.864H263.573C263.573 128.613 245.965 110.465 224.256 110.465C202.547 110.465 184.939 128.557 184.939 150.864H163.065C163.065 116.196 190.516 87.9897 224.256 87.9897C257.995 87.9897 285.446 116.196 285.446 150.864Z" fill="#306FD1" />
      <path d="M224.256 217.39C186.524 217.39 163.065 197.612 163.065 165.754H184.939C184.939 189.858 206.32 194.915 224.256 194.915C242.192 194.915 263.573 189.858 263.573 165.754H285.446C285.446 197.612 261.987 217.39 224.256 217.39Z" fill="#9747FF" />
      <path d="M33.1379 181.823L0 66.0208H22.6388L44.7308 151.033H45.8244L67.9711 66.0208H90.6099L112.592 150.639H113.686L135.614 66.0208H158.253L125.17 181.767H102.804L79.9467 98.2163H78.2515L55.394 181.767H33.0833L33.1379 181.823Z" fill="#306FD1" />
      <path d="M306.608 181.823V66.0206H327.825V84.394H328.973C331.051 78.1572 334.66 73.2688 339.855 69.6728C345.05 66.0768 350.956 64.2788 357.573 64.2788C358.94 64.2788 360.58 64.2788 362.439 64.4474C364.299 64.5598 365.775 64.6721 366.869 64.8407V86.3605C365.994 86.1358 364.408 85.7987 362.166 85.5177C359.924 85.1806 357.682 85.012 355.44 85.012C350.245 85.012 345.652 86.1358 341.605 88.3833C337.559 90.6308 334.387 93.7211 332.036 97.6542C329.684 101.587 328.536 106.082 328.536 111.083V181.767H306.608V181.823Z" fill="#306FD1" />
      <path d="M385.953 181.823V27.4756H407.881V181.823H385.953ZM406.131 142.548L405.967 115.016H409.795L454.69 66.0203H480.938L429.754 121.758H426.309L406.131 142.492V142.548ZM457.096 181.823L416.74 126.815L431.832 111.083L484 181.823H457.096Z" fill="#306FD1" />
    </g>
    <defs>
      <clipPath id="clip0_10701_62188">
        <rect width="484" height="217.39" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
