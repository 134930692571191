export const Vimeo = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0364 0H15.9636C7.14712 0 0 7.14713 0 15.9636V16.0364C0 24.8529 7.14712 32 15.9636 32H16.0364C24.8529 32 32 24.8529 32 16.0364V15.9636C32 7.14713 24.8529 0 16.0364 0Z" fill="#63B4E3" />
    <g clipPath="url(#clip0_4432_8460)">
      <path d="M5.33337 11.7599L6.19471 12.8945C6.19471 12.8945 7.98137 11.4852 8.57604 12.1865C9.17871 12.8945 11.46 21.3959 12.2147 22.9679C12.8734 24.3452 14.7014 26.1625 16.6987 24.8665C18.7147 23.5625 25.3694 17.8799 26.5614 11.1559C27.7507 4.44387 18.54 5.8492 17.5747 11.6999C20.004 10.2412 21.3134 12.2972 20.068 14.6212C18.82 16.9545 17.6747 18.4759 17.0814 18.4759C16.4987 18.4759 16.0374 16.9105 15.3547 14.1932C14.6547 11.3785 14.6547 6.30387 11.7267 6.87987C8.95871 7.42653 5.33337 11.7599 5.33337 11.7599Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_4432_8460">
        <rect width="21.3333" height="21.3333" fill="white" transform="translate(5.33337 5.33325)" />
      </clipPath>
    </defs>
  </svg>
)
