import {
  AUDIENCES, JOB_TYPE_KEYS, PAY_PERIODS, TYPE_OF_JOB
} from 'common/constants'
import { FIELDS } from 'common/enums'
import type { RootState } from 'common/types'
import {
  CARD_ACTIONS_KEYS,
  EXPLORE_PAGE_CARD_TITLE_KEYS, LINK_ERRORS, PROFILE_ACTIONS_KEYS, PROFILE_TABS_KEYS
} from 'features/Translations/constants'
import { createSelector } from 'reselect'

export const selectTranslations = (state: RootState) => state.translations
export const selectTranslationsData = createSelector(selectTranslations, ({ data }) => data)
export const selectIsTranslationsLoading = createSelector(selectTranslations, ({ isLoading }) => isLoading)

export const selectMyNetworkTranslations = createSelector(
  selectTranslationsData,
  ({ myNetwork }) => ({
    title: myNetwork?.title,
    subtitle: myNetwork?.subtitle,
    search: myNetwork?.search,
    sort: myNetwork?.sort,
    sortingFirstTrusted: myNetwork?.sortingFirstTrusted,
    sortingFirstUnTrusted: myNetwork?.sortingFirstUnTrusted,
    trustedBy: myNetwork?.trustedBy,
    trustedTitle: myNetwork?.trustedTitle,
    trustingTitle: myNetwork?.trustingTitle,
    myProfileContacts: myNetwork?.myProfileContacts,
    searchTrustedUserPlaceholder: myNetwork?.searchTrustedUserPlaceholder,
    searchOtherTrustedUserPlaceholder: myNetwork?.searchOtherTrustedUserPlaceholder,
    others: myNetwork?.others,
    firstLevel: myNetwork?.firstLevel,
    secondLevel: myNetwork?.secondLevel,
    groupsLevel: myNetwork?.groupsLevel,
    globalSearchLevel: myNetwork?.globalSearchLevel,
    firstEmptyStateTitle: myNetwork?.firstEmptyStateTitle,
    secondEmptyStateTitle: myNetwork?.secondEmptyStateTitle,
    groupsEmptyStateTitle: myNetwork?.groupsEmptyStateTitle,
    contactsEmptyStateTitle: myNetwork?.contactsEmptyStateTitle,
    globalSearchEmptyStateTitle: myNetwork?.globalSearchEmptyStateTitle,
    selectContactText: myNetwork?.selectContactText,
    contactsEmptyTitle: myNetwork.contactsEmptyTitle,
    contactsEmptyDescription: myNetwork.contactsEmptyDescription,
    networkInviteButtonTitle: myNetwork.networkInviteButtonTitle,
    networkShareModalTitle: myNetwork.networkShareModalTitle,
    networkFiltersFirst: myNetwork.networkFiltersFirst,
    networkFiltersSecond: myNetwork.networkFiltersSecond,
    networkFiltersGroups: myNetwork.networkFiltersGroups,
    networkFiltersAll: myNetwork.networkFiltersAll,
    networkFiltersAllContacts: myNetwork.networkFiltersAllContacts,
    networkFiltersGlobal: myNetwork.networkFiltersGlobal,
    networkModalFiltersTrusted: myNetwork.networkModalFiltersTrusted,
    networkModalFiltersTrusting: myNetwork.networkModalFiltersTrusting,
    networkModalAddButton: myNetwork.networkModalAddButton,
    myNetworkAddContactButton: myNetwork.myNetworkAddContactButton,
    profileTrustedBySummaryText: myNetwork.profileTrustedBySummaryText,
    contactsGlobalShowMore: myNetwork.contactsGlobalShowMore,
    emptyGroupTitle: myNetwork.emptyGroupTitle,
    emptyGroupDescription: myNetwork.emptyGroupDescription,
    groupInviteButtonTitle: myNetwork.groupInviteButtonTitle,
    groupShareModalTitle: myNetwork.groupShareModalTitle,
    groupCopyButtonTitle: myNetwork.groupCopyButtonTitle,
    groupCopiedButtonTitle: myNetwork.groupCopiedButtonTitle,
    groupDropdownButtonNewGroup: myNetwork.groupDropdownButtonNewGroup,
    createGroupModalTitle: myNetwork.createGroupModalTitle,
    editGroupModalTitle: myNetwork.editGroupModalTitle,
    createGroupModalSetPhoto: myNetwork.createGroupModalSetPhoto,
    createGroupModalInputTitle: myNetwork.createGroupModalInputTitle,
    createGroupModalErrorMessage: myNetwork.createGroupModalErrorMessage,
    createGroupModalCancelButton: myNetwork.createGroupModalCancelButton,
    createGroupModalCreateButton: myNetwork.createGroupModalCreateButton,
    createGroupModalUpdateButton: myNetwork.createGroupModalUpdateButton,
    groupDoesNotExistTitle: myNetwork.groupDoesNotExistTitle,
    groupDoesNotExistDesc: myNetwork.groupDoesNotExistDesc,
    groupDoesNotExistButton: myNetwork.groupDoesNotExistButton,
    joinGroupMembersText: myNetwork.joinGroupMembersText,
    joinGroupMemberText: myNetwork.joinGroupMemberText,
    joinGroupBlockedMemberText: myNetwork.joinGroupBlockedMemberText,
    joinPrivateGroupMembersText: myNetwork.joinPrivateGroupMembersText,
    joinPrivateGroupMembersTextSent: myNetwork.joinPrivateGroupMembersTextSent,
    joinGroupButton: myNetwork.joinGroupButton,
    groupMemberOwnerText: myNetwork.groupMemberOwnerText,
    groupMemberButtonApprove: myNetwork.groupMemberButtonApprove,
    groupMemberButtonDeny: myNetwork.groupMemberButtonDeny,
    groupActionShareSettings: myNetwork.groupActionShareSettings,
    groupActionLeaveGroup: myNetwork.groupActionLeaveGroup,
    groupActionDeleteGroup: myNetwork.groupActionDeleteGroup,
    groupActionMoreButton: myNetwork.groupActionMoreButton,
    memberActionTrust: myNetwork.memberActionTrust,
    memberActionUntrust: myNetwork.memberActionUntrust,
    memberActionAddContact: myNetwork.memberActionAddContact,
    memberActionRemoveContact: myNetwork.memberActionRemoveContact,
    memberActionRemoveFromGroup: myNetwork.memberActionRemoveFromGroup,
    deleteGroupModalTitle: myNetwork.deleteGroupModalTitle,
    deleteGroupSecondaryButtonText: myNetwork.deleteGroupSecondaryButtonText,
    deleteGroupPrimaryButtonText: myNetwork.deleteGroupPrimaryButtonText,
    deleteGroupModalDescription: myNetwork.deleteGroupModalDescription,
    leaveGroupModalTitle: myNetwork.leaveGroupModalTitle,
    leaveGroupSecondaryButtonText: myNetwork.leaveGroupSecondaryButtonText,
    leaveGroupPrimaryButtonText: myNetwork.leaveGroupPrimaryButtonText,
    leaveGroupModalDescription: myNetwork.leaveGroupModalDescription,
    removeContactModalTitle: myNetwork.removeContactModalTitle,
    removeContactSecondaryButtonText: myNetwork.removeContactSecondaryButtonText,
    removeContactPrimaryButtonText: myNetwork.removeContactPrimaryButtonText,
    removeContactModalDescription: myNetwork.removeContactModalDescription,
    removeFromGroupModalTitle: myNetwork.removeFromGroupModalTitle,
    removeFromGroupSecondaryButtonText: myNetwork.removeFromGroupSecondaryButtonText,
    removeFromGroupPrimaryButtonText: myNetwork.removeFromGroupPrimaryButtonText,
    removeFromGroupModalDescription: myNetwork.removeFromGroupModalDescription,
    resetLinkModalTitle: myNetwork.resetLinkModalTitle,
    resetLinkSecondaryButtonText: myNetwork.resetLinkSecondaryButtonText,
    resetLinkPrimaryButtonText: myNetwork.resetLinkPrimaryButtonText,
    resetLinkModalDescription: myNetwork.resetLinkModalDescription,
    untrustModalTitle: myNetwork.untrustModalTitle,
    untrustSecondaryButtonText: myNetwork.untrustSecondaryButtonText,
    untrustPrimaryButtonText: myNetwork.untrustPrimaryButtonText,
    untrustModalDescription: myNetwork.untrustModalDescription,
    groupPageEmptySearchTitle: myNetwork.groupPageEmptySearchTitle,
    groupPageEmptySearchSubtitle: myNetwork.groupPageEmptySearchSubtitle,
    groupPageSearchPlaceholder: myNetwork.groupPageSearchPlaceholder,
    groupPageCancelSearchButtonText: myNetwork.groupPageCancelSearchButtonText,
    groupPageInviteButtonTitle: myNetwork.groupPageInviteButtonTitle,
    groupPageSearchButtonTitle: myNetwork.groupPageSearchButtonTitle,
    groupPageRequestsSectionTitle: myNetwork.groupPageRequestsSectionTitle,
    groupPageMembersSectionTitle: myNetwork.groupPageMembersSectionTitle,
    groupPageInviteModalTitle: myNetwork.groupPageInviteModalTitle,
    groupSettingsModalTitle: myNetwork.groupSettingsModalTitle,
    groupSettingsModalSecondaryButtonText: myNetwork.groupSettingsModalSecondaryButtonText,
    groupSettingsModalPrimaryButtonText: myNetwork.groupSettingsModalPrimaryButtonText,
    groupSettingsModalDescription: myNetwork.groupSettingsModalDescription,
    groupSettingsModalLinkSectionTitle: myNetwork.groupSettingsModalLinkSectionTitle,
    groupSettingsModalGroupAccessTitle: myNetwork.groupSettingsModalGroupAccessTitle,
    groupSettingsModalGroupLinkTitle: myNetwork.groupSettingsModalGroupLinkTitle,
    groupSettingsModalApprovalText: myNetwork.groupSettingsModalApprovalText,
    groupSettingsModalCopiedText: myNetwork.groupSettingsModalCopiedText,
    groupSettingsModalCopyText: myNetwork.groupSettingsModalCopyText,
    groupSettingsModalUnableChangePrivacy: myNetwork.groupSettingsModalUnableChangePrivacy,
    groupMemberRequestToJoinText: myNetwork.groupMemberRequestToJoinText
  })
)

export const selectNavBarTranslations = createSelector(
  selectTranslationsData,
  ({
    chatTexts, home, profile, onboarding
  }) => ({
    homeTextNavBar: home.title,
    networkTextNavBar: chatTexts.contacts,
    chatTextNavBar: chatTexts.title,
    profileTextNavBar: chatTexts.listings,
    jobHistory: profile.jobHistory,
    textLegalTerms: onboarding.textLegalTerms,
    privacyPolicy: onboarding.privacyPolicyTitle
  })
)

export const selectInviteViaLinkTranslations = createSelector(
  selectTranslationsData,
  ({ inviteViaLink }) => ({
    inviteViaLink: inviteViaLink?.inviteViaLink,
    copyLink: inviteViaLink?.copyLink,
    copyToClipBoard: inviteViaLink?.copyToClipBoard,
    copyLinkShort: inviteViaLink?.copyLinkShort,
    copyToClipBoardShort: inviteViaLink?.copyToClipBoardShort,
    profileShareLinkCopiedTitle: inviteViaLink?.profileShareLinkCopiedTitle
  })
)

export const selectNotificationsTranslations = createSelector(
  selectTranslationsData,
  ({ notifications }) => ({
    photoMessage: notifications?.photoMessage,
    voiceMessage: notifications?.voiceMessage,
    jobNotificationMessage: notifications?.jobNotificationMessage
  })
)

export const selectProfileTranslations = createSelector(
  selectTranslationsData,
  ({ profile }) => ({
    ...profile,
    [FIELDS.SALARY_MIN]: profile.salaryFrom,
    [FIELDS.SALARY_MAX]: profile.salaryTo,
    [FIELDS.RATE]: profile?.rate,
    [PROFILE_TABS_KEYS.VACANCIES]: profile?.hiring
  } as { [key: string]: string })
)

export const selectMyProfileTranslations = createSelector(
  selectTranslationsData,
  ({ myProfile }) => ({
    ...myProfile as { [key: string]: string },
    [PROFILE_TABS_KEYS.ABOUT]: myProfile?.segmentedControlAbout,
    [PROFILE_TABS_KEYS.OPEN_TO]: myProfile?.segmentedControlOpenTo,
    [PROFILE_TABS_KEYS.VACANCIES]: myProfile?.segmentedControlHiring,
    resumeDeleteButton: myProfile?.resumeDeleteButton,
    vacancyDeleteButton: myProfile?.vacancyDeleteButton,
    resumeDeleteCancelButton: myProfile?.resumeDeleteCancelButton,
    vacancyDeleteCancelButton: myProfile?.vacancyDeleteCancelButton,
    profileEditingCancelButton: myProfile?.profileEditingCancelButton,
    profileEditingSaveButton: myProfile?.profileEditingSaveButton
  })
)

export const selectOtherProfileTranslations = createSelector(
  selectTranslationsData,
  ({ otherProfile }) => ({
    ...otherProfile as { [key: string]: string },
    [PROFILE_ACTIONS_KEYS.ADD_CONTACT]: otherProfile.contactBottomSheetAddContact,
    [PROFILE_ACTIONS_KEYS.SHARE]: otherProfile.contactBottomSheetShare,
    [PROFILE_ACTIONS_KEYS.TRUST]: otherProfile.contactBottomSheetTrust,
    [PROFILE_ACTIONS_KEYS.UNTRUST]: otherProfile.contactBottomSheetUntrust,
    [PROFILE_TABS_KEYS.ABOUT]: otherProfile?.segmentedControlAbout,
    [PROFILE_TABS_KEYS.OPEN_TO]: otherProfile?.segmentedControlOpenTo,
    [PROFILE_TABS_KEYS.VACANCIES]: otherProfile?.segmentedControlHiring,
    shareOtherProfile: otherProfile?.shareOtherProfile,
    errorLoadingPublicResume: otherProfile?.errorLoadingPublicResume,
    errorLoadingPublicVacancy: otherProfile?.errorLoadingPublicVacancy,
    errorLoadingPublicProfile: otherProfile?.errorLoadingPublicProfile
  })
)

export const selectErrorMsgsTranslations = createSelector(
  selectTranslationsData,
  ({ errorMsgs }) => ({
    emptySearchStateTitle: errorMsgs?.emptySearchStateTitle,
    networkEmptySearchStateTitle: errorMsgs?.networkEmptySearchStateTitle,
    emptySearchStateSubTitle: errorMsgs?.emptySearchStateSubTitle,
    emptyNetworkSearchStateSubTitle: errorMsgs?.emptyNetworkSearchStateSubTitle,
    locationTypeError: errorMsgs?.locationTypeError,
    locationError: errorMsgs?.locationError,
    rateError: errorMsgs?.rateError,
    helperJobTitle: errorMsgs?.helperJobTitle,
    helperTextRepeatTag: errorMsgs?.helperTextRepeatTag,
    errorMsgCurrency: errorMsgs?.errorMsgCurrency,
    errorMsgLocationsLimit: errorMsgs?.errorMsgLocationsLimit,
    errorMsgDescriptionLimit: errorMsgs?.errorMsgDescriptionLimit,
    tagsLimitErrorMsg: errorMsgs.tagsLimitErrorMsg,
    helperTextLimit: errorMsgs.helperTextLimit,
    helperTextLimitMin: errorMsgs.helperTextLimitMin,
    locationExistError: errorMsgs.locationExistError,
    wrongLocation: errorMsgs.wrongLocation,
    vacanciesRequiredLocation: errorMsgs.vacanciesRequiredLocation
  })
)

export const selectVacanciesTranslations = createSelector(
  selectTranslationsData,
  ({ vacancies }) => ({
    edited: vacancies?.edited,
    [TYPE_OF_JOB.FULL_TIME]: vacancies?.fullTime,
    [TYPE_OF_JOB.PART_TIME]: vacancies?.partTime,
    [TYPE_OF_JOB.CONTRACTOR]: vacancies?.contractor,
    [TYPE_OF_JOB.SMALL_JOBS]: vacancies?.smallJobs,
    [TYPE_OF_JOB.INTERNSHIP]: vacancies?.internship,
    [TYPE_OF_JOB.VOLUNTEER]: vacancies?.volunteer,
    [TYPE_OF_JOB.ADVISOR]: vacancies?.advisor,
    [TYPE_OF_JOB.BOARD_OF_DIRECTORS]: vacancies?.boardDirectors,
    [TYPE_OF_JOB.PROJECT_BASED]: vacancies?.projectBased,
    [TYPE_OF_JOB.STARTUP_BUSINESS_PARTNER]: vacancies?.startupBusinessPartner,
    payPeriodPiece: vacancies?.payPeriodPiece,
    [AUDIENCES.ONLY_TRUSTS]: vacancies?.onlyTrusts,
    [AUDIENCES.ONLY_ME]: vacancies?.onlyMe,
    [AUDIENCES.MY_NETWORK]: vacancies?.myNetwork,
    [AUDIENCES.STRANGERS]: vacancies?.strangers,
    [JOB_TYPE_KEYS.ON_SITE]: vacancies?.inPerson,
    [JOB_TYPE_KEYS.REMOTE]: vacancies?.remote,
    [JOB_TYPE_KEYS.HYBRID]: vacancies?.hybrid,
    openToEdited: vacancies?.openToEdited,
    openToPublished: vacancies?.openToPublished,
    vacancyDetailOpenToWork: vacancies.vacancyDetailOpenToWork,
    vacancyDetailHiringBy: vacancies.vacancyDetailHiringBy,
    profileTagsPlaceholder: vacancies?.profileTagsPlaceholder
  })
)

export const selectIntroduceSuccessTranslations = createSelector(
  selectTranslationsData,
  ({ introduce }) => ({
    okButton: introduce?.okButton,
    exploreCreatedOkButton: introduce?.exploreCreatedOkButton,
    seeChatButton: introduce?.seeChatButton,
    titleSuccessModal: introduce?.titleSuccessModal,
    accountDeletedModalTitle: introduce?.accountDeletedModalTitle,
    accountDeletedModalDescription: introduce?.accountDeletedModalDescription,
    recommendedBy: introduce?.recommendedBy,
    description: introduce?.description,
    emptyMessageError: introduce?.emptyMessageError,
    messagePlaceholder: introduce?.messagePlaceholder,
    sendButton: introduce?.sendButton,
    alreadyIntroduced: introduce?.alreadyIntroduced,
    chooseSomeoneElse: introduce?.chooseSomeoneElse,
    modalTitle: introduce?.modalTitle,
    chooseSomeoneToIntroduce: introduce?.chooseSomeoneToIntroduce,
    chooseButton: introduce?.chooseButton,
    firstModalTitle: introduce?.firstModalTitle,
    messageTitle: introduce?.messageTitle,
    introEmptyTitle: introduce?.introEmptyTitle,
    introEmptyDescription: introduce?.introEmptyDescription,
    searchIntroUserPlaceholder: introduce?.searchIntroUserPlaceholder,
    [EXPLORE_PAGE_CARD_TITLE_KEYS.INTRODUCTION_BY]: introduce?.introductionBy
  })
)

export const selectChatTextsTranslations = createSelector(
  selectTranslationsData,
  ({ chatTexts }) => ({
    title: chatTexts?.title,
    messageSuccessModal: chatTexts?.messageSuccessModal,
    messageSeenBy: chatTexts?.messageSeenBy,
    chatName: chatTexts?.chatName,
    online: chatTexts.online,
    placeholder: chatTexts.placeholder,
    offline: chatTexts.offline,
    deletedAccount: chatTexts?.deletedAccount,
    chatTextMe: chatTexts?.chatTextMe,
    selectChat: chatTexts?.selectChat,
    messagesEmptyTitle: chatTexts?.messagesEmptyTitle,
    messagesEmptyDescription: chatTexts?.messagesEmptyDescription,
    messagesFileUpload: chatTexts?.messagesFileUpload,
    matchedMessages: chatTexts?.matchedMessages,
    startedVacancyChat: chatTexts?.startedVacancyChat,
    startedSpecialtyChat: chatTexts?.startedSpecialtyChat,
    microphonePermissionDisabled: chatTexts?.microphonePermissionDisabled,
    audioRecordingSecondsLimit: chatTexts?.audioRecordingSecondsLimit,
    voiceMessage: chatTexts?.voiceMessage,
    chatNoMessageSubtitle: chatTexts?.chatNoMessageSubtitle,
    chatNoMessageTitle: chatTexts?.chatNoMessageTitle,
    chatTextAnd: chatTexts?.chatTextAnd,
    chatMenuTextCopy: chatTexts?.chatMenuTextCopy,
    chatMenuTextDelete: chatTexts?.chatMenuTextDelete,
    chatMenuTextReply: chatTexts?.chatMenuTextReply,
    chatMenuTextSave: chatTexts?.chatMenuTextSave,
    chatSearchPlaceholder: chatTexts?.chatSearchPlaceholder
  })
)

export const selectEditProfileTranslations = createSelector(
  selectTranslationsData,
  ({ editProfile }) => editProfile
)

export const selectOnboardingTranslations = createSelector(
  selectTranslationsData,
  ({ onboarding }) => ({
    enterYourNumber: onboarding.enterYourNumber,
    phoneInputPlaceholder: onboarding.phoneInputPlaceholder,
    resendCodeWaiting: onboarding.resendCodeWaiting,
    yourName: onboarding.yourName,
    yourLastName: onboarding.yourLastName,
    yourUserName: onboarding.yourUserName,
    helperFirstName: onboarding.helperFirstName,
    helperLastName: onboarding.helperLastName,
    discardChangesSubTitle: onboarding.discardChangesSubTitle,
    discardChangesTitle: onboarding.discardChangesTitle,
    discardButtonText: onboarding.discardButtonText,
    cancelButtonText: onboarding.cancelButtonText,
    landingTitle: onboarding.landingTitle,
    landingPhoneNumber: onboarding.landingPhoneNumber,
    landingLogIn: onboarding.landingLogIn,
    landingSignUp: onboarding.landingSignUp,
    authLogIn: onboarding.authLogIn,
    authSignUp: onboarding.authSignUp,
    textLegalAgree: onboarding.textLegalAgree,
    textLegalTerms: onboarding.textLegalTerms,
    resendCode: onboarding.resendCode,
    authenticationUrlPrivacy: onboarding.authenticationUrlPrivacy,
    authenticationUrlTerms: onboarding.authenticationUrlTerms,
    textLegalPrivacy: onboarding.textLegalPrivacy,
    textLegalAnd: onboarding.textLegalAnd,
    buttonContinue: onboarding.buttonContinue,
    verificationEnterCodeFor: onboarding.verificationEnterCodeFor,
    dreamJobSubTitle: onboarding.dreamJobSubTitle,
    photoTitle: onboarding?.photoTitle,
    photoSubtitle: onboarding?.photoSubtitle,
    dreamJobDescription: onboarding?.dreamJobDescription,
    displayNameSubtitle: onboarding?.displayNameSubtitle,
    displayNameDescription: onboarding?.displayNameDescription,
    titleFirstTime: onboarding.titleFirstTime,
    displayNameTextTitle: onboarding.displayNameTextTitle,
    backButton: onboarding.backButton,
    popularChoice: onboarding.popularChoice,
    yourChoice: onboarding.yourChoice,
    invalidCode: onboarding.invalidCode,
    jobTextSubtitle: onboarding.jobTextSubtitle,
    employerSubtitleStages: onboarding.employerSubtitleStages,
    employeeSubtitleIndustries: onboarding.employeeSubtitleIndustries,
    employerSubtitleIndustries: onboarding.employerSubtitleIndustries,
    jobTextTitle: onboarding.jobTextTitle,
    employeeSelectSpeciality: onboarding.employeeSelectSpeciality,
    selectLocation: onboarding.selectLocation,
    [JOB_TYPE_KEYS.ON_SITE]: onboarding.switchButtonOnSite,
    [JOB_TYPE_KEYS.REMOTE]: onboarding.switchButtonRemote,
    [PAY_PERIODS.PER_HOUR]: onboarding.vacancyPeyPeriodHour,
    [PAY_PERIODS.PER_DAY]: onboarding.vacancyPeyPeriodDay,
    [PAY_PERIODS.PER_WEEK]: onboarding.vacancyPeyPeriodWeek,
    [PAY_PERIODS.PER_MONTH]: onboarding.vacancyPeyPeriodMonth,
    [PAY_PERIODS.PER_YEAR]: onboarding.vacancyPeyPeriodYear,
    [PAY_PERIODS.FIXED_PRICE]: onboarding.vacancyFixedPrice,
    switchButtonsTitle: onboarding.switchButtonsTitle,
    dreamJobKeywords: onboarding.dreamJobKeywords,
    tagsTitle: onboarding.tagsTitle,
    tagsDescription: onboarding.tagsDescription,
    tagsSubtitle: onboarding.tagsSubtitle,
    vacancyTitle: onboarding.vacancyTitle,
    vacancyDescription: onboarding.vacancyDescription,
    salaryTitle: onboarding.salaryTitle,
    salarySubtitle: onboarding.salarySubtitle,
    nameTextTitle: onboarding.nameTextTitle,
    nameTextSubTitle: onboarding.nameTextSubTitle,
    jobTextDescription: onboarding.jobTextDescription,
    currency: onboarding.currency,
    optional: onboarding.optional,
    salaryFrom: onboarding.salaryFrom,
    salaryTo: onboarding.salaryTo,
    companyInfoWebsite: onboarding.companyInfoWebsite,
    companyInfo: onboarding.companyInfo,
    alreadySwitchButton: onboarding.alreadySwitchButton,
    placeholderVerificationCode: onboarding.placeholderVerificationCode,
    msgForLimitExceeded: onboarding.msgForLimitExceeded,
    msgForInvalidNumber: onboarding.msgForInvalidNumber,
    footerCopyright: onboarding.footerCopyright,
    msgForInvalidPhoneNumber: onboarding.msgForInvalidPhoneNumber,
    msgForNoInternetAccess: onboarding.msgForNoInternetAccess,
    generalErrorMsg: onboarding.generalErrorMsg,
    privacyPolicy: onboarding.privacyPolicyTitle,
    pageDoesNotExistTitle: onboarding.pageDoesNotExistTitle,
    pageDoesNotExistDesc: onboarding.pageDoesNotExistDesc,
    pageDoesNotExistButton: onboarding.pageDoesNotExistButton,
    splashScreenTitle: onboarding.splashScreenTitle,
    nameStepButtonContinue: onboarding.nameStepButtonContinue,
    nameStepButtonBack: onboarding.nameStepButtonBack,
    jobButtonNext: onboarding.jobButtonNext,
    jobButtonBack: onboarding.jobButtonBack,
    dreamJobsButtonNext: onboarding.dreamJobsButtonNext,
    dreamJobsButtonBack: onboarding.dreamJobsButtonBack,
    photoButtonStart: onboarding.photoButtonStart,
    photoButtonBack: onboarding.photoButtonBack
  })
)

export const selectHomeTranslations = createSelector(
  selectTranslationsData,
  ({ home }) => ({
    // Card not found
    cardNotFoundTitle: home.cardNotFoundTitle,
    homeCardNotFoundGoToHome: home.homeCardNotFoundGoToHome,
    // Home
    homeTitle: home.homeTitle,
    homeSearchPlaceholder: home.homeSearchPlaceholder,
    startChatButton: home.startChatButton,
    skipButton: home.skipButton,
    filtersTitle: home.filtersTitle,
    iosApplication: home.iosApplication,
    androidApplication: home.androidApplication,
    homeDescription: home.homeDescription,
    exploreNoMatchesTitle: home.exploreNoMatchesTitle,
    exploreNoMatchesDescription: home.exploreNoMatchesDescription,
    trustedBy: home.trustedBy,
    other: home.other,
    others: home.others,
    trustedPath: home.trustedPath,
    explorePageTitle: home.title,
    explorePageFrom: home.explorePageFrom,
    explorePageStartChat: home.explorePageStartChat,
    buttonGoToGroupChat: home.buttonGoToGroupChat,
    explorePageChat: home.explorePageChat,
    explorePageIntroduce: home.explorePageIntroduce,
    explorePageArchiveJob: home.explorePageArchiveJob,
    explorePageEditJob: home.explorePageEditJob,
    exploreLinkTitle: home.exploreLinkTitle,
    exploreLinkCopyMessage: home.exploreLinkCopyMessage,
    exploreInviteViaLink: home.exploreInviteViaLink,
    exploreCreateOpenTo: home.exploreCreateOpenTo,
    [CARD_ACTIONS_KEYS.SHARE]: home.cardPopupActionsShare,
    [CARD_ACTIONS_KEYS.HIDE]: home.cardPopupActionsHide,
    [CARD_ACTIONS_KEYS.EDIT]: home.cardPopupActionsEdit,
    [CARD_ACTIONS_KEYS.MOVE_ARCHIVE]: home.cardPopupActionsArchive,
    [CARD_ACTIONS_KEYS.DELETE]: home.cardPopupActionsDelete,
    cardPopupActionsShareTitle: home.cardPopupActionsShareTitle,
    cardPopupActionsShareCopied: home.cardPopupActionsShareCopied,
    cardPopupActionsShareCopyText: home.cardPopupActionsShareCopyText,
    cardPopupActionsShareFacebookTitle: home.cardPopupActionsShareFacebookTitle,
    cardPopupActionsShareLinkedInTitle: home.cardPopupActionsShareLinkedInTitle,
    cardPopupActionsShareWhatsAppTitle: home.cardPopupActionsShareWhatsAppTitle,
    cardPopupActionsShareGmailTitle: home.cardPopupActionsShareGmailTitle,
    cardPopupActionsShareGmailBody: home.cardPopupActionsShareGmailBody,
    cardPopupActionsShareWhatsAppBody: home.cardPopupActionsShareWhatsAppBody,
    exploreCreateHiring: home.exploreCreateHiring,
    exploreShowMore: home.exploreShowMore,
    exploreShowFewer: home.exploreShowFewer,
    exploreCreatedTitle: home.exploreCreatedTitle,
    [EXPLORE_PAGE_CARD_TITLE_KEYS.HIRING]: home.homeMatchesHiring,
    [EXPLORE_PAGE_CARD_TITLE_KEYS.OPEN_TO]: home.homeMatchesOpenTo,
    homeOpenChatModalOk: home.homeOpenChatModalOk,
    homeOpenChatModalCancel: home.homeOpenChatModalCancel,
    homeOpenChatModalTitle: home.homeOpenChatModalTitle,
    homeOpenChatModalDescription: home.homeOpenChatModalDescription,
    homeButtonCreateJob: home.homeButtonCreateJob,
    homeFiltersOffering: home.homeFiltersOffering,
    homeFiltersOpenTo: home.homeFiltersOpenTo,
    homeFiltersMyJobs: home.homeFiltersMyJobs
  })
)

export const selectListingTranslations = createSelector(
  selectTranslationsData,
  ({ listing }) => ({
    listingTitle: listing.listingTitle,
    listingTitleOtherProfile: listing.listingTitleOtherProfile,
    listingEmptyStateTitle: listing.listingEmptyStateTitle,
    listingFiltersAll: listing.listingFiltersAll,
    listingFiltersOffering: listing.listingFiltersOffering,
    listingFiltersOpenTo: listing.listingFiltersOpenTo,
    listingFiltersAllOther: listing.listingFiltersAllOther,
    listingFiltersOfferingOther: listing.listingFiltersOfferingOther,
    listingFiltersOpenToOther: listing.listingFiltersOpenToOther,
    listingEmptyAll: listing.listingEmptyAll,
    listingEmptyOpenTo: listing.listingEmptyOpenTo,
    listingEmptyOffering: listing.listingEmptyOffering,
    ...listing as { [key: string]: string }
  })
)

export const selectArchiveTranslations = createSelector(
  selectTranslationsData,
  ({ archive, profile }) => ({
    archiveTitle: profile.jobHistory,
    archiveEmptyStateTitle: archive.archiveEmptyStateTitle,
    ...archive as { [key: string]: string }
  })
)

export const selectSupportTranslations = createSelector(
  selectTranslationsData,
  ({ support }) => ({
    ...support as { [key: string]: string }
  })
)

export const selectSettingsTranslations = createSelector(
  selectTranslationsData,
  ({ settings }) => ({
    contactSupport: settings.contactSupport,
    viewProfile: settings.viewProfile,
    deleteProfile: settings.deleteProfile,
    signOut: settings.signOut,
    signOutAlertTitle: settings.signOutAlertTitle,
    profileDeleteMessage: settings.profileDeleteMessage,
    profileDeleteAreYouSureMessage: settings.profileDeleteAreYouSureMessage,
    shareProfile: settings.shareProfile,
    shareMyProfileModalTitle: settings.shareMyProfileModalTitle,
    cancelButtonText: settings.cancelButtonText,
    settingsButtonText: settings.settingsButtonText,
    settingsSidebarTitle: settings.settingsSidebarTitle,
    settingsSidebarContactSupport: settings.settingsSidebarContactSupport,
    settingsSidebarPrivacyPolicy: settings.settingsSidebarPrivacyPolicy,
    settingsSidebarTerms: settings.settingsSidebarTerms,
    settingsSidebarDeleteProfile: settings.settingsSidebarDeleteProfile,
    settingsSidebarLanguage: settings.settingsSidebarLanguage,
    settingsSidebarLanguageEnglish: settings.settingsSidebarLanguageEnglish,
    settingsSidebarLanguageRussian: settings.settingsSidebarLanguageRussian,
    settingsSidebarLanguageArmenian: settings.settingsSidebarLanguageArmenian,
    settingsSidebarCurrentLanguageCode: settings.settingsSidebarCurrentLanguageCode
  })
)

export const selectErrorModalTranslations = createSelector(
  selectTranslationsData,
  ({ errorModal }) => ({
    jobDetailOutdatedInfo: errorModal.jobDetailOutdatedInfo,
    errorModalContent: errorModal.errorModalContent,
    errorMessageText: errorModal.errorMessageText,
    errorButton: errorModal.errorButton,
    errorMessage: errorModal.errorMessage,
    errorProfileDeletedTitle: errorModal.errorProfileDeletedTitle,
    errorProfileDeletedDesc: errorModal.errorProfileDeletedDesc,
    errorShareTokenExpiredTitle: errorModal.errorShareTokenExpiredTitle,
    errorShareTokenExpiredDesc: errorModal.errorShareTokenExpiredDesc
  })
)

export const selectToastMessages = createSelector(
  selectTranslationsData,
  ({ toastMessages }) => ({
    vacancyLimitMessage: toastMessages?.vacancyLimitMessage,
    specialtyLimitMessage: toastMessages?.specialtyLimitMessage,
    errorNewVacancy: toastMessages?.errorNewVacancy,
    errorNewSpecialty: toastMessages?.errorNewSpecialty,
    errorDeleteVacancy: toastMessages.errorDeleteVacancy,
    errorDeleteSpecialty: toastMessages.errorDeleteSpecialty,
    errorHideVacancy: toastMessages.errorHideVacancy,
    errorHideSpecialty: toastMessages.errorHideSpecialty,
    errorCloseVacancy: toastMessages.errorCloseVacancy,
    errorCloseSpecialty: toastMessages.errorCloseSpecialty,
    errorEditVacancy: toastMessages.errorEditVacancy,
    errorEditSpecialty: toastMessages.errorEditSpecialty,
    specialiyEditedSuccessMessage: toastMessages.specialtyEditedSuccessMessage,
    vacancyEditedSuccessMessage: toastMessages.vacancyEditedSuccessMessage,
    exploreHiringCreatedAlertMessage: toastMessages.exploreHiringCreatedAlertMessage,
    exploreOpenToCreatedAlertMessage: toastMessages.exploreOpenToCreatedAlertMessage,
    listingsOpenToCreatedAlertMessage: toastMessages.listingsOpenToCreatedAlertMessage,
    listingsHiringCreatedAlertMessage: toastMessages.listingsHiringCreatedAlertMessage,
    errorGlobalSearchMessage: toastMessages.errorGlobalSearchMessage,
    errorGetMyGroups: toastMessages.errorGetMyGroups,
    errorGetGroup: toastMessages.errorGetGroup,
    errorAddNewGroup: toastMessages.errorAddNewGroup,
    errorUpdateGroup: toastMessages.errorUpdateGroup,
    errorUpdateGroupPrivacy: toastMessages.errorUpdateGroupPrivacy,
    errorRemoveContact: toastMessages.errorRemoveContact,
    errorLeaveGroup: toastMessages.errorLeaveGroup,
    errorDeleteGroup: toastMessages.errorDeleteGroup,
    errorRemoveUserFromGroup: toastMessages.errorRemoveUserFromGroup,
    errorJoinGroup: toastMessages.errorJoinGroup,
    errorApproveJoinRequest: toastMessages.errorApproveJoinRequest,
    errorDenyJoinRequest: toastMessages.errorDenyJoinRequest,
    errorResetGroupLink: toastMessages.errorResetGroupLink
  })
)

export const selectPublicTranslations = createSelector(
  selectTranslationsData,
  ({ publicData }) => ({
    signIn: publicData.signIn,
    joinUs: publicData.joinUs,
    joinUsModal: publicData.joinUsModal,
    useAppSuggestion: publicData.useAppSuggestion
  })
)

export const selectAppLinksTranslations = createSelector(
  selectTranslationsData,
  ({ appLinks }) => appLinks
)

export const selectLinkErrorsTranslations = createSelector(
  selectProfileTranslations,
  selectErrorMsgsTranslations,
  (profileTranslations, errorsTranslations) => ({
    [LINK_ERRORS.URL_PATTERN]: profileTranslations.correctLinkErrorMsg,
    [LINK_ERRORS.EMPTY_NAME]: profileTranslations.requiredLinkNameErrorMsg,
    [LINK_ERRORS.EMPTY_URL]: profileTranslations.requiredLinkUrlErrorMsg,
    [LINK_ERRORS.DUPLICATE]: profileTranslations.duplicateLinksErrorMsg,
    [LINK_ERRORS.URL_LENGTH]: errorsTranslations.helperTextLimit,
    [LINK_ERRORS.NAME_LENGTH]: errorsTranslations.helperTextLimit
  })
)
