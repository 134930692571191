export const Github = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4432_8429)">
      <path d="M15.9999 0C12.2007 0.000161529 8.52552 1.38638 5.63195 3.91062C2.73837 6.43487 0.81524 9.93241 0.206655 13.7774C-0.401929 17.6225 0.34375 21.5641 2.31027 24.897C4.27678 28.2299 7.33581 30.7366 10.94 31.9686C11.74 32.1122 12.04 31.62 12.04 31.1894C12.04 30.7998 12.02 29.5079 12.02 28.134C7.99999 28.8927 6.95999 27.1293 6.64 26.2065C6.28492 25.3091 5.72204 24.5137 5.00001 23.8893C4.44001 23.5817 3.64002 22.823 4.97999 22.8025C5.49165 22.8595 5.98238 23.042 6.41061 23.3347C6.83883 23.6274 7.19194 24.0216 7.43999 24.484C7.65882 24.887 7.95308 25.2419 8.30589 25.5281C8.6587 25.8144 9.06314 26.0264 9.49603 26.1522C9.92892 26.2779 10.3817 26.3148 10.8286 26.2608C11.2754 26.2068 11.7074 26.0629 12.0999 25.8373C12.1692 25.0034 12.5317 24.2236 13.12 23.6433C9.55998 23.2332 5.84 21.8182 5.84 15.5435C5.81752 13.9131 6.4043 12.3357 7.47999 11.1347C6.99085 9.71772 7.04808 8.16266 7.63999 6.78748C7.63999 6.78748 8.97993 6.35684 12.04 8.46896C14.658 7.73071 17.4219 7.73071 20.0399 8.46896C23.0998 6.33636 24.4399 6.78748 24.4399 6.78748C25.0319 8.16264 25.0891 9.71773 24.5999 11.1347C25.6788 12.3336 26.2661 13.9124 26.2399 15.5435C26.2399 21.8387 22.4999 23.2332 18.9399 23.6433C19.3217 24.0401 19.6158 24.5165 19.8022 25.0402C19.9885 25.5638 20.0628 26.1225 20.0199 26.6782C20.0199 28.8723 19.9999 30.6357 19.9999 31.1894C19.9999 31.6201 20.2999 32.1327 21.0999 31.9687C24.6977 30.7266 27.748 28.214 29.7061 24.8794C31.6642 21.5448 32.4028 17.6052 31.79 13.7639C31.1772 9.92261 29.2529 6.4296 26.3606 3.90843C23.4683 1.38726 19.7962 0.00202583 15.9999 0Z" fill="#222629" />
    </g>
    <defs>
      <clipPath id="clip0_4432_8429">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
