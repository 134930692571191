import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { SpinnerIcon } from 'common/icons_V2/SpinnerIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { GroupParticipantInfo } from 'features/Contacts/Network/GroupPage/components/GroupParticipantInfo'
import {
  GroupLocationState, GroupRequestStatus, GroupType, Privacy
} from 'features/Contacts/Network/GroupPage/types'
import { isBlockedMember } from 'features/Contacts/Network/GroupPage/utils'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { joinToGroup } from 'features/MyProfile/actions'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IGroupJoinModal {
  isOpen: boolean
  onClose: (skipRedirect: boolean) => void
  group: GroupType | null
  isLoading?: boolean
}

export const GroupJoinModal: FC<IGroupJoinModal> = ({
  isOpen, onClose, group, isLoading
}: IGroupJoinModal) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<GroupLocationState>()
  const myUid = useSelector(getMyUid)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const isPrivateGroup = group?.privacy === Privacy.Private
  const isRequestSent = isPrivateGroup && group?.request?.status === GroupRequestStatus.Applied
  const [isJoining, setIsJoining] = useState(false)

  const handleJoinGroup = async () => {
    if (isRequestSent || !group) return
    setIsJoining(true)
    await dispatch(joinToGroup(
      group.url,
      myUid,
      group,
      (alreadySent: boolean) => {
        setIsJoining(false)
        if (alreadySent) {
          if (!isPrivateGroup) {
            onClose(true)
            history.push({
              state: location.state,
              pathname: ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, group.url)
            })
          }
        } else {
          onClose(true)
          if (!location.pathname.startsWith(ROUTES.MESSAGES)) {
            history.push(ROUTES.CONTACTS)
          }
        }
      }
    ))
  }

  const getGroupMemberText = (): string => {
    return group?.members?.length === 1
      ? myNetworkTranslations.joinGroupMemberText
      : myNetworkTranslations.joinGroupMembersText
  }

  const getGroupInfo = () => {
    let buttonText
    let buttonType

    if (isBlockedMember(myUid, group)) {
      return { buttonText, buttonType }
    }
    if (isRequestSent) {
      buttonText = myNetworkTranslations.joinPrivateGroupMembersTextSent
      buttonType = ButtonType.SECONDARY
    } else if (isPrivateGroup) {
      buttonText = myNetworkTranslations.joinPrivateGroupMembersText
      buttonType = ButtonType.TRANSPARENT
    } else {
      buttonText = myNetworkTranslations.joinGroupButton
      buttonType = ButtonType.DEFAULT
    }

    return { buttonText, buttonType }
  }
  const { buttonText, buttonType } = getGroupInfo()

  return (
    <Modal
      onClose={() => { onClose(false) }}
      isOpen={isOpen}
      width={464}
      primaryButtonText={buttonText}
      primaryButtonOnClick={handleJoinGroup}
      footerStyles={styles.footerStyles}
      isPrimaryButtonLoading={isJoining}
      primaryButtonType={buttonType}
      isLoading={isLoading || !group}
    >
      <div className={styles.joinModalContainer}>
        {isLoading || !group ? (
          <div className={cn(styles.spinner)}>
            <SpinnerIcon spinner />
          </div>
        ) : (
          <>
            <GroupParticipantInfo photoURL={group.photoURL} photo={group.photo} groupName={group.name} />
            <div className={styles.joinModalDescription}>
              <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.membersCount}>
                {group?.members?.length}
              </Typography>
              <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p">
                {getGroupMemberText()}
              </Typography>
            </div>
            {isBlockedMember(myUid, group) && (
              <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.blockedText}>
                {myNetworkTranslations.joinGroupBlockedMemberText}
              </Typography>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}
