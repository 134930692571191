export const Medium = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4432_8437)">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 0C24.8369 0 32 7.16313 32 16C32 24.8369 24.8369 32 16 32C7.16313 32 0 24.8369 0 16C0 7.16313 7.16313 0 16 0Z" fill="#222629" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3 9.96118C14.635 9.96118 17.3387 12.6649 17.3387 15.9999C17.3387 19.3349 14.635 22.0387 11.3 22.0387C7.96559 22.0387 5.26184 19.3349 5.26184 15.9999C5.26184 12.6649 7.96559 9.96118 11.3 9.96118ZM25.6737 10.9149C26.2618 10.9149 26.7381 13.1918 26.7381 15.9999C26.7381 18.8081 26.2618 21.0849 25.6737 21.0849C25.0862 21.0849 24.6093 18.8081 24.6093 15.9999C24.6093 13.1918 25.0862 10.9149 25.6737 10.9149ZM20.9625 10.4381C22.65 10.4381 24.0175 12.9281 24.0175 15.9999C24.0175 19.0718 22.65 21.5618 20.9625 21.5618C19.275 21.5618 17.9068 19.0718 17.9068 15.9999C17.9068 12.9281 19.275 10.4381 20.9625 10.4381Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_4432_8437">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
